
@import '../../../../styles/partials/palettes';
@import '../../../../styles/partials/typography';
@import '../../../../styles/partials/mixin';
@import '../../../../styles/partials/fonts';

.sidebar-modal  {
    &.small-modal{
        > .MuiDialog-container{
            > .MuiPaper-root {
                position: fixed;
                top: 0;
                right: 0;
                margin: 0;
                border-radius: 0;
                width: 400px;
                min-height: 100vh;
                display: block;
                max-width: 100%;
            }
        }
        
    }
}
.sidepanel{
    &__header {
        padding: 10px 20px;
        border-bottom: 1px solid $border-color;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3{
            font-size: $medium-l-size;
            font-weight: 500;
        }
        svg{
            width: 18px;
            height: 18px;
        }
    }
    &__content{
        padding: 20px;
        .info-list {
            .MuiPaper-root {
                position: static;
                min-height: inherit;
                box-shadow: none;
                padding: 0;
                margin: 0;
                width: 100%;
                .MuiAccordionSummary-root{
                    min-height: inherit;
                    padding: 5px 0;
                    .MuiAccordionSummary-content{
                        margin: 0;
                        font-size: $medium-size;
                        font-weight: 500;
                    }
                }
                .MuiCollapse-root {
                    .MuiAccordionDetails-root{
                        padding: 0;
                    }
                }
                .MuiCollapse-entered{
                    margin-bottom: 10px;
                }
            }
            ul{
                li {
                    a {
                        display: flex;
                        align-items: center;
                        padding: 5px 0;
                        color: $secondrycolor;
                        font-size: $medium-size;
                        svg{
                            margin-right: 10px;
                        }
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        
       
        .action{   
            margin-top: 10%;                  
            .stroke-btn{
                @include stroke-btn;
                width: 100%;
                border-radius: 20px;
            }
        }
    }
    &.info-panel-detail {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        background: $white-color;
        height: 100%;
        display: flex;
        flex-direction: column;
        h3{
            display: flex;
            align-items: flex-start;
            button{
                margin-right: 5px;
            }
        }
        p{
            margin-bottom: 15px;
            font-size: $medium-size;
            line-height: 26px;
        }
        .sidepanel__content {
            padding: 20px;
            flex: 1 1 100%;
            overflow: auto;
        
            ul{
                list-style: unset;
                padding-left: 16px;
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 15px;

            }
            ol{
                list-style: auto;
                padding-left: 16px;
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 15px;
            }
        }
        .bp3-icon{
            vertical-align: inherit;
        }
    }
}
