@import '../../../../styles/partials/grid';
@import '../../../../styles/partials/palettes';
@import '../../../../styles/partials/typography';
@import '../../../../styles/partials/mixin';
@import '../../../../styles/partials/modal';

.external-resources-modal{
    @include smallpopup;
    .MuiPaper-root{
        padding: 0;
        width: 800px;
        max-width: 100%;
    }
    text-align: center;
    padding: 20px;
    .external-resources-modal-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $border-color; 
        padding: 10px;
        margin-bottom: 15px;    
    }
    .bp3-icon{
        justify-content: center;
        display: flex;
    }
    h3{
        font-size: $medium-l-size;
        font-weight: 500;
    } 

    &__content{
        padding: 15px;
        padding-top: 0;
        text-align: left;
        h4 {
            font-weight: 600;
            margin-bottom: 20px;
            margin-top: 10px;
            font-size: $medium-size;
        }
        &-list{
            display: flex;
            flex-wrap: wrap;
            .bp3-tag {
                font-size: $body-size !important;
                background-color: #d5e5f3 !important;
                color: $secondrycolor !important;
                padding: 7px 15px !important;
                margin-right: 10px;
                margin-bottom: 10px;
                border-radius: 4px; 
            }
        }
    }
    
}