@import '../../../../styles/partials/grid';
@import '../../../../styles/partials/palettes';
@import '../../../../styles/partials/typography';
@import '../../../../styles/partials/mixin';
@import '../../../../styles/partials/fonts';



.roleDialoge{
    background-color:$white-color !important;
    width: 100% !important;
    max-width: 580px;
    .bp3-dialog-header{
      padding:12px 20px;
      .bp3-heading{
        color: $black !important;
        font-weight: 500 !important;
        font-size: 20px;
  
      }
    }
    .warningMsg {
      background-color: #FBEFDA;
      border-radius: 5px;
      padding: 16px;
      font-size:$small-size;
      color:#6B4A0F;
      line-height: normal;
    }
   button.btn{
     @include nj_btn;
     text-align: center;
   }
   .collapsable-card {
       .rounded-b{
           justify-content: space-between !important;
           background-color: #E8E8E8;
           color: $black;
           &:hover{
            background-color: #E2ECF4 !important;
           }
           }
           .bp3-tag.bp3-round{
            border-radius: 5px !important;
            line-height: normal !important;
            min-height: inherit !important;
            font-weight: normal;
            color: #575353 !important;
            background-color: #C9CBD4;
            font-size: $small-size;
       }

   }
  }
  
  .collapsable-card >div:first-child {
    justify-content: space-between !important;
    background-color: #F4F5F7 !important;
    color: $black !important;
    & + .bp3-collapse{
        background-color: #F4F5F7;
        padding:0 8px !important;
        .bp3-collapse-body{
            padding:10px 16px;
            margin-bottom: 10px;
        } 
       }
} 
  

  
  
