@import "../../../../styles/partials/palettes";
@import "../../../../styles/partials/typography";
@import "../../../../styles/partials/mixin";
@import "../../../../styles/partials/fonts";
@import "../../../../styles/partials/grid";

.publishNotes-topbar {
  padding: 0px 0px 0 70px;
      h2{
        font-size: 22px;
        font-family: "Palanquin";
        font-weight: bold;
        padding-left: 20px;
      }
    .filterbar{
        padding-top: 0px;
        padding-bottom: 0px;
        margin-bottom: 0px;
      .filterCol {
          flex: 0 0 18%;
          max-width: 18%;
          margin-right: 10px;
          .filterRange{
              width: 100%;
          }
      }
    } 
  }