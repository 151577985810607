
@import '../../../../styles/partials/palettes';
@import '../../../../styles/partials/typography';
@import '../../../../styles/partials/mixin';
@import '../../../../styles/partials/fonts';
@import '../../../../styles/partials/modal';


.send-feedback{
    .MuiPaper-root{
        padding: 0;
        width: 400px;
        max-width: 100%;

    }
    &__header {
        padding: 10px 20px;
        border-bottom: 1px solid $border-color;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        font-family: $medium-l-size;
        h3{
            font-family: $medium-l-size;
            font-weight: 700;
        }
        svg{
            width: 18px;
            height: 18px;
        }
    }
    &__content{
        padding: 20px;
        .css-1oshf1q-MuiFormControl-root{
            width: 100%;
            .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root{
                font-family: $primaryfont;
            }
        }
        .MuiTextField-root{
            width: 100%;
            margin-top: 15px;
        }
        
       
        .button-group{   
            margin-top: 20px;
            text-align: right;                  
            .stroke-btn{
                @include stroke-btn;
            }
        }
    }

}
.MuiPaper-root .MuiList-root li{
    justify-content: flex-start !important;
}