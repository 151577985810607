@import '../../styles/partials/grid';
@import '../../styles/partials/palettes';
@import '../../styles/partials/typography';
@import '../../styles/partials/mixin';
@import '../../styles/partials/fonts';

@media(min-width:1280px){
  .container {
    max-width: 1200px !important;
 }
}
.container {
    margin: auto;
}


  .welcomePage-wrapper{
    @include grid;
    line-height: 1.5;
    &__header{
      border-bottom: 1px solid $border-color_2;
      padding: 0 0px 16px 0px;
    }
    &__content{
      font-size: $medium-size; 
      color: $black;
      ul{
        margin: 25px 0px;
        list-style-type: disc;
        padding-left: 20px;
        li{
          margin-bottom: 3px;
        }
      } 
      .blueHighlated-Txt{
        background-color: $bluecolor_1;
        padding: 15px 22px;
        border-radius: 5px;
      }
      .action{
        margin-top: 30px;
      }
    }
    &__txt{
      font-size: $medium-size;
      margin: 30px 0 15px 0;
    }

    .nj-btn {
      @include nj_btn;
      padding:12px 20px;
    }
    &-videoDiv{
      width: 100%;
      height: 360px;
      background-color: $blackshade_1;
    }
    .video-Notes{
      font-size: 12px;
      font-weight: 200;
      font-style: italic;
      margin-top: 12px;
    }
    .cstmColPad{
      padding-left: 45px;
      padding-right: 45px;
    }


  }
  @media(max-width:$l-devices){
    .welcomePage-wrapper{
      &__header{
        padding:0 0 16px 0;
      }
      .cstmColPad {
          padding:5px 15px;
        }
        .action{
          margin-bottom: 30px;
        }
    }

  }