@import '../../../../../styles/partials/grid';
@import '../../../../../styles/partials/palettes';
@import '../../../../../styles/partials/typography';
@import '../../../../../styles/partials/mixin';
@import '../../../../../styles/partials/fonts';

.strategies-content{
    margin: 0 20px;
    &__head {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-end;
        margin-bottom: 10px;
        button{
            display: flex;
            border: 1px solid $border-color;
            align-items: center;
            padding: 3px 10px;
            border-radius: 5px;
            .bp3-icon {
                margin-left: 5px;
            }
            &:hover{
                box-shadow: 0 0 5px $border-color;
            }
        }
    }
    &__list{
        > ul{
            > li{
                
                border-radius: 5px;
                margin-bottom: 15px;
                border: 1px solid $border-color;
                box-shadow: 0 0 5px $border-color;
                
                &:hover{
                    border-color:#619CD1;  
                    box-shadow: 0 0 5px #619CD1;                  
                }
            }
        }
        &-head{
            background: $light-grey;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 10px 15px;
            border-bottom: 1px solid $border-color;
            h3{
                font-size: $medium-size;
                font-weight: 600;
                text-transform: capitalize;
                span{
                    font-size: 12px;
                    background-color: #C9CBD4;
                    border-radius: 5px;
                    padding: 5px 10px;
                    margin-left: 10px;
                }

                .custom-strategy-district{
                    font-size: 12px;
                    background-color: #20285B;
                    color: #ffffff;
                    border-radius: 3px;
                    padding: 5px 10px;
                    margin-left: 10px;
                }
            }
            ul{
                display: flex;
                li{
                    margin-left: 15px;                    
                    button{
                        .bp3-icon{
                            margin-left: 5px;
                        }
                        &:hover{
                            opacity: .8;
                        }
                    }
                    svg{
                        fill: $secondrycolor !important;
                    }
                    button{
                        margin-left: 15px;
                    }
                }
            }
        }
        &-content{
            padding: 10px 15px;
            &--link{
                margin: 10px 0;
                margin-bottom: 0;
                ul{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    li{
                        border-right: 1px solid $border-color;
                        padding: 0 15px;
                        line-height: 12px;
                        a{
                            color:$secondrycolor;
                            display: flex;
                            align-items: center;
                            &:hover{
                                text-decoration: underline;
                            }
                            svg{
                                margin-right: 5px;
                            }

                        }
                        &:first-child{
                            padding-left: 0;
                        }
                        &:last-child{
                            border: none;
                        }
                    }
                }
            }
            &--domain{
                label{
                    font-weight: 500;
                    margin-bottom: 5px;
                    margin-top: 10px;
                    display: block;
                }
                ul{
                    display: flex;
                    flex-wrap: wrap;
                    li{
                        margin-right: 8px;
                        margin-bottom: 8px;
                        background: $light-grey;
                        border-radius: 5px;
                        border-left: 3px solid;
                        padding: 5px 10px;                        
                    }
                }
            }
        }
        .strategies-action {
            display: flex;
            align-items: center;
            margin-top: 30px;
            margin-bottom: 30px;
            .load-more{
                text-align: center;
                flex: 1;
                .stroke-btn{
                    @include stroke-btn;
                }
            }
        }
        
        .disclaimer {
            a{
                color: $secondrycolor;
                text-decoration: underline;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
    &__list2{
        > ul{
            > li{
                
                border-radius: 5px;
                margin-bottom: 15px;
                border: 1px solid $border-color;
                box-shadow: 0 0 5px $border-color;
                
                &:hover{
                    border-color:#619CD1;  
                    box-shadow: 0 0 5px #619CD1;                  
                }
            }
        }
        &-head{
            background: $light-grey;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            display: flex;
           // flex-wrap: wrap;
            justify-content: space-between;
            padding: 10px 15px;
            border-bottom: 1px solid $border-color;
            h3{
                font-size: $medium-size;
                font-weight: 600;
                text-transform: capitalize;
                span{
                    display: inline-block;
                    line-height: 1;
                    font-size: 12px;
                    background-color: #C9CBD4;
                    border-radius: 5px;
                    padding: 5px 10px;
                    margin-left: 8px;
                }

                .custom-strategy-district{
                    font-size: 12px;
                    background-color: #20285B;
                    color: #ffffff;
                    border-radius: 3px;
                    padding: 5px 10px;
                    margin-left: 8px;
                }
            }
            ul{
                display: flex;
                li{
                    margin-left: 15px;                    
                    button{
                        .bp3-icon{
                            margin-left: 5px;
                        }
                        &:hover{
                            opacity: .8;
                        }
                    }
                    svg{
                        fill: $secondrycolor !important;
                    }
                    button{
                        margin-left: 15px;
                    }
                }
            }
        }
        &-content{
            padding: 10px 15px;
            &--link{
                margin: 10px 0;
                margin-bottom: 0;
                ul{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    li{
                        border-right: 1px solid $border-color;
                        padding: 0 15px;
                        line-height: 12px;
                        a{
                            color:$secondrycolor;
                            display: flex;
                            align-items: center;
                            &:hover{
                                text-decoration: underline;
                            }
                            svg{
                                margin-right: 5px;
                            }

                        }
                        &:first-child{
                            padding-left: 0;
                        }
                        &:last-child{
                            border: none;
                        }
                    }
                }
            }
            &--domain{
                label{
                    font-weight: 500;
                    margin-bottom: 5px;
                    margin-top: 10px;
                    display: block;
                }
                ul{
                    display: flex;
                    flex-wrap: wrap;
                    li{
                        margin-right: 8px;
                        margin-bottom: 8px;
                        background: $light-grey;
                        border-radius: 5px;
                        border-left: 3px solid;
                        padding: 5px 10px;                        
                    }
                }
            }
        }
        .strategies-action {
            display: flex;
            align-items: center;
            margin-top: 30px;
            margin-bottom: 30px;
            .load-more{
                text-align: center;
                flex: 1;
                .stroke-btn{
                    @include stroke-btn;
                }
            }
        }
        
        .disclaimer {
            a{
                color: $secondrycolor;
                text-decoration: underline;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
}

.filter-dropdown{
    &.dropdown-list{
        li{
            
                padding: 5px 10px;
                min-width: 150px;
                display: block;
                width: 150px;
                color: $secondrycolor;
                &:hover{
                    background: $hover-bg;
                }
                .bp3-icon {
                    margin-right: 5px;
                }
            
        }
    }
}

.action-bar {
                display: block;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-top: 5px;
                margin-bottom: 10px;
                margin-bottom: 24px;
                .nj_btn{
                    @include nj_btn;
                }
                .stroke-btn{
                    @include stroke-btn;
                }
                .button-group{
                    button{
                        margin-left: 10px;
                    }
                }
            }

@media(max-width:$xl-devices){
    .strategies-content{
        &__list-head{
        flex-direction: column;
        li{
            margin-left: 0px !important;
            & + li{
                margin-left: 15px !important;
            }
        }
        }

    }
}