@import '../../../../../../../styles/partials/grid';
@import '../../../../../../../styles/partials/fonts';
@import '../../../../../../../styles/partials/mixin';
@import '../../../../../../../styles/partials/palettes';

.add-strategic{
    @include grid;
    .toolbar svg{
        display: inline-block;
        margin: 0 2px;
        path{
            fill: $secondrycolor;
        }
    }
    &__wrapper{
        padding:  0 20px;
        .nj-row{
            margin-left: -10px;
            margin-right: -10px;
        }
        [class*="nj-md-"] {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }

        input{
            box-sizing: content-box !important;
        }
        .MuiInputLabel-asterisk,.req_asterisk{
            color: $asterisk_bg;
        }
        .req_asterisk{
            margin-left: 3px;
            font-size: 16px;
        }
        .MuiFormLabel-root {
            font-size: $body-size;
        }
        .MuiInputBase-formControl{
            font-size: $body-size ;
        }
        h3{
            font-size: $medium-l-size;
            color: $black;
            font-family: $secondryfont;
            font-weight: bold;
            position: relative;
            border-bottom: 1px solid $border-color;
            padding-bottom: 15px;
            padding-top: 5px;
            &:after{
                content: "";
                position: absolute;
                left: 0;
                bottom: 0px;
                width: 42px;
                height: 5px;
                background-color: $secondrycolor;
            }
        }
        &-fields{
            clear: both;
            padding-top: 30px;
            .add-strategicComponent-btn{
                position: absolute;
                right: 10px;
                bottom: 2px;
                    .stroke-btn{
                        padding: 5px 0px;
                        border: none;
                        box-shadow: none;
                        margin-right: 2px;
                        font-family: $primaryfont;
                        line-height: 1.5;
                        color: $secondrycolor;
                        &:hover{
                            box-shadow: none !important;
                        }
                        .bp3-icon{
                            vertical-align: middle;
                        }
                    }

            }
            .add-strategic-btn{
                button {
                    margin-right: 10px;
                    @include stroke-btn;
                    padding: 6px 20px;
                    display: flex;
                    align-items: center; 
                    font-family: $primaryfont;           
                }
            }
            .editorBox{
                margin-top: 5px;
                .ql-toolbar{
                    &.ql-snow{
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                    }
                    &.ql-snow .ql-formats{
                        margin-right: 8px !important;
                    }
                }
                .ql-toolbar.ql-snow + .ql-container.ql-snow{
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
            .componentRow{
                margin-top: 10px;
                position: relative;
                input{
                    padding-right: 32px;
                }
                .coponentdelete_Btn{
                    position: absolute;
                    right: 10px;
                    top: 5px;
                    opacity: 0;
                    span{
                        color: $secondrycolor;
                    }
                }
                &:hover{
                    .coponentdelete_Btn{
                        opacity: 1;
                    }  
                }
            }
        }
    }
    &-urlforms{
        .cstmLabel{
            font-size: $medium-size;
        }
        .formFields {
            margin: 4px 0 10px 0;
        }
    }
    &-imageUploadSec{
        .uploadSection{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            align-items: center;
            background-color: $grey-color_shade1;
            padding: 16px;
            &-btnDiv{
                margin-bottom: 0px;
                input{
                    display: none;
                }
            }
            &-btn{
                margin-right: 10px;
                margin-bottom: 0px !important;
                @include stroke-btn;
                font-size: $body-size;
                padding: 6px 20px; 
                text-transform: capitalize;
                margin: 0;
                cursor: pointer;
            }
            & *{
                margin-bottom: 8px;
            }
        }
        .uploadFileNames{
            background-color: $grey-color_shade1;
            padding: 16px;
            margin-top: 16px;
            margin-bottom: 16px;
            P{
                text-align: center;
            }
  
            .MuiTableContainer-root{
               background-color: $white-color;
            }
            .filesName_Chips{
                display: inline-block;
                background-color: #fff;
                border-radius: 50px;
                padding: 8px 40px 6px 12px;
                vertical-align: middle;
                font-size: 13px;
                position: relative;
                line-height: normal;
                margin-right: 8px;
                margin-bottom: 5px;
                &::before{
                    vertical-align: middle;
                    margin-right: 5px;
                    width: 18px;
                    height: 20px;
                    display: inline-block;
                }
                .chipDelete_Btn{
                    position: absolute;
                    right: 10px;
                    top: 8px;
                    padding: 2px 2px;
                    height: 18px;
                    width: 18px;
                    border-radius: 50px !important;
                    background-color: $white-color;
                    &:hover{
                        background-color:$grey-color_shade1;
                    }
                }
            }
            .doc-icon::before{
                content:url(../../../../../../../static/icons/svg/wordIcon.svg);
            }
            .docx-icon::before{
                content:url(../../../../../../../static/icons/svg/wordIcon.svg);
            }
            .pdf-icon::before{
                content:url(../../../../../../../static/icons/svg/pdfIcon.svg);
            }
            .jpg-icon::before{
                content:url(../../../../../../../static/icons/svg/jpgIcon.svg);
            }
            .png-icon::before{
                content:url(../../../../../../../static/icons/svg/pngIcon.svg);
            }
            .csv-icon::before{
                content:url(../../../../../../../static/icons/svg/csvIcon.svg);
            }
            .xlsx-icon::before{
                content:url(../../../../../../../static/icons/svg/exceLicon.svg);
            }
            .ppt-icon::before{
                content:url(../../../../../../../static/icons/svg/powerpoint.svg);
            }
            .pptx-icon::before{
                content:url(../../../../../../../static/icons/svg/pptx.svg);
            }
            .hyperlink-icon::before{
                content:url(../../../../../../../static/icons/svg/hyperlink-icon.svg);
            }

        }
        .addURLbtn{
            @include stroke-btn;
            font-size: $body-size;
            padding: 6px 10px; 
            margin: 0;
            cursor: pointer;
        }
    }
    h5{
        font-size: $medium-size;
        font-weight: 500;
        margin-bottom: 16px;
    }
    &__button-group{
        text-align: right;
        margin-top: 16px;
        margin-bottom: 16px;
        button{
            margin: 0 5px;
        }
        .nj-btn{
            @include nj_btn;
        }
        .stroke-btn{
            @include stroke-btn;
        }
    }
    .bp3-icon-plus {
        margin-top: -1px;
        margin-right: 1px;
    }
}

@media(max-width:1150px){
    .addURLbtn{
        margin-top: 10px !important;
    }
}

@media(max-width:991px){
    .add-strategic__wrapper{
        .add-strategic-btn{
            margin-top: 8px;
        }
        &-fields{
            .MuiFormControl-fullWidth{
                margin-top: 8px;  
            }
        }
        .formFields{
            .MuiFormControl-fullWidth{
                margin-top: 0px;  
            } 
        }
        .editorBox{
            margin-bottom: 16px;
        }
    }
}
.srategies_DomainList li{
    padding: 0px !important;
}

.ql-editor{
    min-height:200px;
    max-height:200px;
}

.isErrorOverview, .isErrorSupportEvd {
    border: 1px red solid;
    border-radius: 4px;

    .ql-snow {
        border: none !important;
    }

    .ql-toolbar.ql-snow {
        border-bottom: 1px solid #ccc !important;
    }
}

.isDomainError {
    border-color: red !important;
}
