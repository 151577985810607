@import '../../../../styles/partials/palettes';
@import '../../../../styles/partials/typography';
@import '../../../../styles/partials/mixin';
@import '../../../../styles/partials/fonts';
@import '../../../../styles/partials/grid';


.SciNote{
    &-wrapper{
        .breadcrumbs{
            padding: 0px;
            ul li{
                padding-right: 5px;
                a{
                    color: $secondrycolor;  
                }
            }
        }
        .sci-Toolbar{
            font-family: $secondryfont;
            font-size: 28px;
            line-height: 36px;
            font-weight: 700;
            color: $primarycolor;  
            margin-top: 0;
            margin-bottom: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px;
            .stroke-btn{
                @include stroke-btn;
            }
           .infoIcon{
            font-size: $body-size;
            font-weight: 500;
            color: $secondrycolor;
            font-family:$primaryfont;
               .info-icon{
                width: 20px;
                height: 20px;
                display: inline-block;
                line-height: 18px;
                border: 1px solid #155b98;
                border-radius: 50%;
                margin-right: 2px;
               }
           }
        }
        .planning{
            display: flex;
        }
        .pn-description{word-break: break-all;}
        .planning__midd {
            padding: 0 20px;
            float: left;
            width: 100%;
        }
        .planning__left{
            width: 350px;
            height: 100vh;
            padding-top: 16px;
            background:$strategic-filter-bg;
            max-height: inherit;
            &-searchbar{
                h2{
                    font-size: 18px;
                    font-family: $secondryfont;
                    font-weight: bold;
                }
            }
            &-links{
                li{
                    padding: 6px 24px 6px 0px;
                    position: relative;
                    &.notReadableContent a{
                     font-weight: 500;
                    }
                    &.readableContent{
                        opacity: 0.8;
                        strong{
                            font-weight: normal;
                        }
                        button.editBtn {
                            position: absolute;
                            right: -5px;
                            top: 12px;
                            opacity: 0;
                            color: $secondrycolor;
                        }
                        &:hover{
                            button.editBtn{
                                opacity: 1;
                            }
                        }
                    }
                    a{
                        width: 100%;
                        display: block;
                        padding:  6px 20px;
                        color: $black;
                        font-size: $body-size;
                    }
                    .additionalInfo{
                        padding:  0 20px;
                    }
                    &:hover,&:focus{
                        background-color: $menuHover;
                    }
                }
            }
            .meetings{
                padding:  8px 20px;
                border-bottom: 1px solid $border-color;
                color: $black;
            }
            .agendaCalender{
                padding:  8px 20px;
                margin-top: 50px;
                a{
                    color: $secondrycolor;
                    text-decoration: underline;
                }
                svg{
                   display: inline-block;
                   vertical-align: sub;
                   margin-right: 8px;
                }
            }
            &-searchbar{
                position: relative;
                button.sciFilterBtn {
                    position: absolute;
                    right: 30px;
                    top: 10px;
                    svg{
                        color: #5c7080;
                    }
                }
                .bp3-icon-search{
                    margin-top: 12px;
                    margin-bottom: 12px;
                }
                input{
                    padding-right: 30px;
                    height: auto !important;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    border-radius: 5px;
                }
            }
            .sciFilterFeilds {
                background-color:$white-color;
                padding: 16px;
                border-radius: 5px;
                box-shadow: 0px 0px 5px $border-color;
                margin-top: 8px;
                position: absolute;
                left: 20px;
                top: 40px;
                width: 94%;
                z-index: 1;
                .sciDatePicker{
                    margin-top: 8px;
                    .MuiStack-root{
                        overflow: visible;
                        .MuiTextField-root{
                            min-width: inherit;
                        }
                    }
                    input{
                        padding-top: 9px;
                        padding-bottom: 9px;
                        padding-right: 0px;
                        font-size: $body-size;
                    }
                 } 
                 .sciFilterBtn {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    margin-top: 32px;
                    margin-bottom: 8px;
                    .clearFilter{
                        color: $secondrycolor;
                    }
                    .nj-btn{
                        @include nj_btn;
                        margin-left: 12px;
                    }
                }

            }
        }
        .collapse-toggle{
            & > .planning__left {
                margin-left: -350px;
            }
            .planning__midd{
                // width: calc(100vw - 335px);
                width: calc(100vw - 90px);
            }
  
        } 

    }
    &-topBar{
        padding: 0px 20px;
    }
}

/*----Left Bar css----*/
.sci-leftbar{
    height: calc(100vh + 6rem);
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $border-color;
    background-color: $leftBar_bg;
    width: 70px;
    float: left;
    z-index: 2;
    li{
        position: relative;
        button{
            width: 40px;
            height: 40px;
            display: block;
            background-color: $leftBar_bg;
            border-radius: 50px;  
            border: 2px solid $secondrycolor; 
            margin: 16px auto;
            min-width: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                width: 20px;
                height: 25px;
                path{
                    fill: $secondrycolor;
                }
            }   
        }
        &.activeItem{
            button{
                background-color: $secondrycolor;
                outline: 2px solid $white-color;
                svg{
                    path{
                        fill: $white-color;
                    }
                }
            }
        } 
    }
}


.planning__left, .planning__midd {
    transition: 500ms;
}
.collapse-toggle > .planning__left {
    margin-left: -240px;
}
.left-bar-toggle{
    z-index: 1;
}


/*-----SCI Middle Content SCSS------*/
.sciMid{
    &-topBar{
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 32px 0 16px 0;
     margin-bottom: 16px;
     button{
         @include stroke-btn;
     }
     .agenda{
        font-size: $medium-l-size;
        font-family: $secondryfont;
        font-weight: 400;
        color: $greyShade_3;
        text-transform: uppercase;
     }
     h2{
         font-size: $large-size;
         font-family: $secondryfont;
         font-weight: bold;
         margin-top: 8px;
     }
    }
    &-content{
        .accordionRow{
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
            margin-bottom: 16px;
            border-radius: 5px;
            overflow: hidden;
            .MuiPaper-elevation1{
                box-shadow: none;
            }
            .MuiTypography-root{
                font-family: $primaryfont;
                font-size: $body-size;
            }
             .MuiAccordionSummary-root{
              background-color: $light-grey;
              border-bottom:1px solid $border-color;
              min-height: inherit;
              .MuiTypography-root{
                  font-weight: 600;
              }
              .MuiAccordionSummary-content{
                  &.Mui-expanded{
                      margin: 12px 0;
                  }
              }
          }
            .stroke-btn{
                @include stroke-btn;
                text-transform: capitalize;
                & + .stroke-btn{
                    margin-left: 14px;
                }
            }
            .accodionActions{
                padding:10px 16px;
            }
            .MuiAccordionDetails-root{
                padding: 16px 16px 0;
            }
        }
    }
}

.cols{
    flex-grow: 1;
    max-width: 100%;
    flex-basis: 0;
    -webkit-box-flex: 1;
}

.SciNote-topBar{
    border-bottom:2px solid #cccccc !important;
}

.stroke-btn {
    padding: 7px 20px !important;
    border-radius: 5px !important;
    font-weight: 600 !important;
    font-size: 13px;
    color: #155b98 !important;
    background-color: #fff !important;
    border: 1px solid #155b98;
    box-shadow: 0px 1px 3px #cccccc;
    line-height: 21px;
    display: inline-block;
    transition: all ease-in-out 0.1s;
    // text-transform: capitalize !important;

}

.stroke-btn .capital {
    text-transform: capitalize !important;
}


.stroke-btn:hover{
    text-decoration: none !important;
}