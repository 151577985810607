$primarycolor: #20285B;
$secondrycolor: #155b98;
$bluecolor_1: #EDF4FB;
$leftBar_bg: #E5EEF5;
$greyShade_3: #747474;
$disabled_feilds:#e7ecef;
$bodycolor: #383838;
$border-color: #cccccc;
$border-color_2: #cccccc;
$white-color: #fff;
$menuHover:#D5DFE8;
$grey-color: #4C4C4C;
$grey-color_shade1: #f2f2f2;
$success: #60B952;
$warning: #F9A72D;
$hover-bg:#EAF5FF;
$light-grey: #F4F5F7;
$black: #000;
$status-progress:#FFE4BC;
$status-notStarted:#DDDDDD;
$status-completed:#D7F3D2;
$profile-circle-bg:#fdece0;
$strategic-filter-bg:#EAECF0;
$tableHeader_bg : #20275A;
$asterisk_bg : #d32f2f;
$error_bg : #d32f2f;
$blackshade_1: #f5f5f5;
$black_shade-1 : #f5f5f5;
$gray_shade-1: #c4c4c4;
$purple_shade-1: #5665C1;
$playbuttonBg : #ED4141;
$accepted:    #2F961E;
$greenscore : #00A800;
$redscore : #E04141;
$grey_3: #707070;
$resourse_tabButton : #EAECF0;
$resourse_tabButton_Txt : #575353;
$gery_shade:#EBEBEB;