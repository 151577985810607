@import '../../../../styles/partials/fonts';
@import '../../../../styles/partials/palettes';
@import '../../../../styles/partials/typography';
.planning__left-list ul li.active {
    background:$hover-bg !important;
}

.planning__left-list ul li {
    display: block;
    width: 100%;
    position: relative;
    padding-left: 45px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    font-size:$medium-size;
}

.sort-option{
    padding:5px 10px;
}

.sort-option:hover{
    background:$hover-bg !important;
    cursor: pointer;
}

.goal-list-filter{
    ol{
        li{
            min-width: 150px;
        }
    }
}