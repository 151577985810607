@mixin smallpopup {   
    .MuiPaper-root{
            max-width: 500px;
            padding: 20px;
        }
    }

@mixin largepopup {   
    .MuiPaper-root{
            width: 500px;
            padding: 20px;
        }
    }

