@import '../../../../styles/partials/palettes';
@import '../../../../styles/partials/typography';
@import '../../../../styles/partials/mixin';
@import '../../../../styles/partials/fonts';

.large-modal.view-details-modal{
    .MuiPaper-root{
        width: 1000px;
        max-width: 100%;
        .modal-title {
            padding: 10px 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $border-color;
            margin-bottom: 15px;
            h3{
                font-size: $medium-l-size;
                font-weight: 500;
            }
        }
        .summary-modal-content {
            padding: 10px 15px;
            h4 {
                text-align: left;
                font-weight: 600;
                font-size: $medium-size;
            }            
        }
        .bp3-tabs{
            .bp3-tab-list{
                border-bottom: 2px solid $border-color;
                padding: 0 20px;
                .bp3-tab{
                    font-size: $medium-size;
                    color: $grey-color;
                    padding: 5px;
                    border-bottom: 3px solid transparent;
                    font-weight: 500;
                    &[aria-selected="true"]{
                        color: $secondrycolor;
                        border-bottom-color: $secondrycolor;                        
                    }
                    &:hover{
                        color: $secondrycolor;
                        border-bottom-color: $secondrycolor;   
                    }
                }
                .bp3-tab-indicator-wrapper{
                    display: none;
                }
            }
        }
        .view-detail-cnt{
            padding: 20px;
            padding-top: 0;
            p{
                margin-bottom: 15px;
                font-size: $medium-size;
                line-height: 26px;
            }
            ul{
                li{
                    font-size: $medium-size;
                    line-break: 26px;
                    margin-bottom: 10px;
                    position: relative;
                    padding-left: 18px;
                    &:after{
                        content: "";
                        position: absolute;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background: #333;
                        left: 0;
                        top: 9px;
                    }
                }
            }
        }
    }
}

