@import '../../../../../styles/partials/grid';
@import '../../../../../styles/partials/fonts';
@import '../../../../../styles/partials/mixin';
@import '../../../../../styles/partials/palettes';

.filterbar{
    padding: 12px 20px 12px 52px;
    background-color: $strategic-filter-bg;
    position: relative;
    margin-bottom: 12px;
    .filter-icon {
        position: absolute;
        left: 20px;
        top: 15px;
        width: 30px;
        height: 30px;
        border: 1px solid #333;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        svg{
            display: inline;
        }
    }
    .MuiFormControl-root{
        padding: 0;
        margin: 0;
        *{
            font-family: $primaryfont;
            font-size: $body-size;
        }
        .MuiOutlinedInput-root {
            background-color: $white-color;
        }
        svg{
            font-size: 24px;
        }
    }
    .show-recom {
        margin-top: 6px;
        display: flex;
        align-items: center;
        .MuiButtonBase-root{
        }
    }
    .filter-input{
        margin-left: 10px;
        width:300px;
        
        .MuiInputLabel-root{
            font-family: $primaryfont;
            font-size: $body-size;
        }
        .MuiOutlinedInput-root{
            .MuiInputBase-input{
                padding: 9px 14px;
            }
        }
    }
}


.filterbar__list {
    padding: 10px;
    padding-top: 0;
    padding-bottom: 0;
    position: sticky;
    top: 0;
    background: $white-color;
    z-index: 9;
     ul {
        display: flex;
        flex-wrap: wrap;
        li {
            background: #eee;
            margin: 5px;
            border-radius: 5px;
            padding: 5px 14px;
            display: flex;
            align-items: center;
            border-left: 3px solid;
            span.bp3-icon {
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }
}
.clear-btn {
    position: sticky;
    bottom: 0;
    text-align: right;
    background: $white-color;
    z-index: 9;
    padding: 5px 10px;
    border-top: 1px solid $border-color;
}

.css-6hp17o-MuiList-root-MuiMenu-list{
    .checkbox-list{
        padding: 5px 10px;
        .MuiCheckbox-root{
            padding: 0;
            margin-right: 5px;
        }
        .MuiTypography-body1{
            font-family: $primaryfont;
            font-size: $body-size;
        }
    }
}
.StrategiesListFilter{
    .filterbar{
        display: flex;
        width: 100%;
        align-items: center;
    }
    .custom-strategy-checkbox {
        padding-left: 16px;
    }
    .filterCol {
        flex: 0 0 18%;
        max-width:25%;
        margin-right:10px;
    }
}
.MuiCheckbox-root .MuiFormControlLabel-label{
    font-family: "SF Pro Display" !important;
    font-weight: 400;
    font-size: $body-size;
}

@media(max-width:$l-devices){
    .strategies-content__list-content--link ul{
        li{
            padding: 0 10px;
        }
    }
}