@use "sass:math";

$columns: 12;
$pad: 0 5px;
$relative: relative;

@mixin grid {
    .nj-row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
        *{
            box-sizing: border-box;
        }
    }
    @for $width from 1 through $columns {
        .nj-sm-#{$width}, .nj-md-#{$width}, .nj-lg-#{$width} {
            flex: 100%;
            max-width:  100%;
            padding: $pad;
            position: $relative;
        }
    }
    @media (min-width:768px){
        @for $width from 1 through $columns {
            .nj-sm-#{$width} {
                flex: math.div($width, $columns) * 100%;
                max-width: math.div($width, $columns) * 100%;
                padding: $pad;
                position: $relative;
            }
        }
    }

    @media (min-width:992px){
        @for $width from 1 through $columns {
            .nj-md-#{$width} {
                flex: math.div($width, $columns) * 100%;
                max-width: math.div($width, $columns) * 100%;
                padding: $pad;
                position: $relative;
            }
        }
    }

    @media (min-width:1140px){
        @for $width from 1 through $columns {
            .nj-lg-#{$width} {
                flex: math.div($width, $columns) * 100%;
                max-width: math.div($width, $columns) * 100%;
                padding: $pad;
                position: $relative;
            }
        }
    }
}
