@import '../../../../../../styles/partials/palettes';
@import '../../../../../../styles/partials/typography';
.domainfilter-dropdown {
    .MuiPaper-root{
        box-shadow: 0 0 6px $border-color;
        ul {
            li {
                display: block;
                padding: 5px 10px;
                color: $secondrycolor;
                min-width: 200px;
                button{
                    display: block;
                    width: 100%;
                    text-align: left;
                }
                &:hover{
                    background-color: $hover-bg;
                }
            }
        }
    }

    
}
.d-flex-inline{   
     display: inline-block;
    text-align: center;
    padding-right: 5px;
    line-height: normal;
    width: 70px;
    vertical-align: middle;}
