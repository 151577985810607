@import '../../../styles/partials/grid';
@import '../../../styles/partials/fonts';
@import '../../../styles/partials/mixin';
@import '../../../styles/partials/palettes';
@import '../../../styles/partials/typography';

div.body-container:has(div.teampage_container) {
    padding: 0 !important;
}

.bg-gray-100 {
    background-color: $white-color !important;
}
.teampage_container {
    @include grid;
    .breadcrumbs {
        padding: 5px 20px;
        ul {
            display: flex;
            li {
                padding-right: 5px;
                a {
                    color: $secondrycolor;
                }
            }
        }
    }

    .toolbar {
        @include toolbar;
        .playButton{
            .playButtonBg{
                background-color: $playbuttonBg;
                display: inline-flex;
                width: 20px;
                height: 20px;
                border-radius: 100px;
                vertical-align: middle;
                padding: 0 3px;
                margin-right: 5px;
                align-items: center;
                span{
                    color: $white-color;
                    vertical-align: middle;
                }
            }
        }
    }

    &__tab {
        .bp3-tabs {
            .bp3-tab-list {
                border-bottom: 1px solid $border-color;
                padding: 0 20px;

                .bp3-tab {
                    font-size: 16px;
                    // color: $disable;
                    padding: 5px;
                    border-bottom: 3px solid transparent;
                    font-weight: 500;
                    background-position: center;
                    transition: background 0.8s;

                    &[aria-selected="true"] {
                        color: $secondrycolor;
                        border-bottom-color: $secondrycolor;
                    }

                    &:hover {
                        color: $secondrycolor;
                        border-bottom-color: $secondrycolor;
                        background: #fff radial-gradient(circle, transparent 1%, #fff 1%) center/15000%;
                    }
                    &:active {
                        background-color: #b7cfff !important;
                        background-size: 100%;
                        transition: background 0s;
                    }
                }

                .bp3-tab-indicator-wrapper {
                    display: none;
                }
            }
        }

        .bp3-tab-panel {
            margin-top: 0;

        }
    }
    .teamContent{
        margin: 0 20px;
        padding-top: 10px;
    }
}


.remove-team-member{
    font-size: 16px;
    padding-top: 10px;
}


@media(max-width:$l-devices){
    .strategic-plan{
        &__tab{
            .bp3-tab{
                font-size: $body-size !important;
                margin-right: 8px !important; 
            }
        }
    }
}