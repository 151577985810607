@import '../../styles/partials/grid';
@import '../../styles/partials/palettes';
@import '../../styles/partials/typography';
@import '../../styles/partials/mixin';
@import '../../styles/partials/fonts';

.onboardingGetStarted{
    &-wrapper{
        position: fixed;
        bottom: 32px;
        right: 32px;
        width: 350px;
        z-index: 999;
      
    }
    &-acc{
        border-radius: 50px !important ;
        background-color: transparent !important;
        
        .yellowBadge{
            width: 25px;
            height: 25px;
            background-color: #FAB334;
            border-radius: 50px;
            color: #000;
            display: flex;
            align-items: center;
            font-weight: bold;
            justify-content: center;
            font-size: 16px;
            position: absolute;
            right: 50px;
        }
        &.Mui-expanded{
            border-top-left-radius:  5px !important;
            border-top-right-radius:  5px !important;
            border-bottom-right-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
        }
        .MuiAccordionSummary-root{
            background-color:$purple_shade-1 !important;
            color: $white-color !important;
            min-height: inherit !important;
            border-radius: 50px ;
            padding-top: 3px;
            padding-bottom: 3px;
            &.Mui-expanded{
                border-top-left-radius:  5px;
                border-top-right-radius:  5px;
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
                .MuiAccordionSummary-expandIconWrapper{
                    transform: rotate(0deg);
                } 
            }
            svg{
                font-size: 25px;
                color: $white-color !important;
            }
            .bp3-icon svg{
                color: $success !important;
                background-color: $white-color;
                border-radius: 50px;
            }
            p{
                font-size: 18px;
                font-weight: bold;
                font-family: $primaryfont;
                margin-left: 5px;
            }

            .MuiAccordionSummary-content{
                display: flex;
                align-items: center;
                margin: 0px;
                .bp3-icon{
                    margin-left: auto;
                }
            }
            .MuiAccordionSummary-expandIconWrapper{
                left: 10px;
                position: relative;
                transform: rotate(180deg);
            }
        }
        .MuiAccordionSummary-expandIconWrapper svg{
            font-size: 35px;
        }
        .MuiAccordionDetails-root{
            background-color: $white-color;
            margin-top: -1px;
        }
        li{
            font-size: 16px;
            color: $primarycolor;
            padding: 5px 0;
            font-family: $primaryfont;
            padding-left: 25px;
            position: relative;
            &.stageDone{
                svg {
                    color:$success;
                }
            }
            &.stageNotDone{
                svg {
                    color:$gray_shade-1;
                }
            }
            span{
                margin-right: 3px;
                position: absolute;
                left: 0;
                top: 8px;
            }
            
        }
    }
}