.tooltip {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 330px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 126%;
  left: -90px;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
