@import '../../../../../styles/partials/palettes';
@import '../../../../../styles/partials/typography';

.no-graph-found{
    &.notfound-message{            
        text-align: center;           
        margin-top: 10px;
        max-width: 350px;
        margin: 0 auto;
        padding-top: 5%;
        .notfound-message_inner{
            background-color: #F8F8F8;
            border: 2px dotted $border-color;
            border-radius: 5px;
            padding: 25px;
        }
        h3{
            border-bottom: 1px solid $border-color;
            padding-bottom: 5px;
            margin-bottom: 10px;
            font-weight: 500;
        }
        &.fullwidth-message{
            padding:  0;
            padding-top: 0;
            max-width: 100%;
            margin-right: 20px;
            h3{
                border: none;
                margin: 0;
            }
        }
        p{
            span{
                color: $secondrycolor;
            }
        }
    }
}
.noGraphData{
    width: 500px;
    height: 300px;
    margin: auto;
    position: relative;
    margin-top: 50px;
}
.notfound-message.no-graph-found {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    margin-left: 10px;
}
.GraphData{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    .graphDiv{
        flex: 0 0 auto;
        width: 50%;
        canvas{
            width: 100% !important;
        }
    }
}
.indicatorDiv{
    width: 400px;
    height: 500px;
    max-height: 400px;
    overflow-y: scroll;
    padding-left: 50px;
    ul{
        list-style-type: none;
        margin: 0px;
        li{
            font-size:$body-size;
            font-weight: 500;
            position: relative;
            padding-left: 30px;
            margin-bottom: 5px;
            .indicatorCircle{
                position: absolute;
                width: 8px;
                height: 8px;
                display: inline-block;
                border-radius: 100px;
                left: 0;
                top: 10px;
                .graphTrack{
                    width: 30px;
                    display: block;
                    position: relative;
                    top: 3px;
                    left: -10px;
                }
            }
            .indicatorSquare{
                position: absolute;
                width: 0;
                height: 0;
                width: 8px; 
                height: 8px; 
                left: 0;
                top: 10px;
                .graphTrack{
                    width: 22px;
                    height: 1px;
                    display: block;
                    position: relative;
                    top: 3px;
                    left: -7px;
                }
            }
            .indicatorTriangle{
                position: absolute;
                width: 0;
                height: 0;
                border-left: 6px solid white;
                border-right: 6px solid white;
                border-bottom: 12px solid transparent;
                background-color: "white"; 
                left: 0;
                top: 10px;
                .graphTrack{
                    width: 22px;
                    height: 1px;
                    display: block;
                    position: relative;
                    top: 5px;
                    left: -10px;
                }
            }
            .indicatorDiamond{
                position: absolute;
                left: 0;
                top: 10px;
                display: table-cell;
                height: 8px;
                transform: rotate(-45deg);
                vertical-align: middle;
                width:8px;
                .graphTrack{
                    width: 22px;
                    height: 0.5px;
                    display: block;
                    position: relative;
                    transform: rotate(45deg);
                    top: 3px;
                    left: -6px;
                }
            }
        }
    }
}