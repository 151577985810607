@import '../../../../styles/partials/palettes';
@import '../../../../styles/partials/typography';
@import '../../../../styles/partials/mixin';
@import '../../../../styles/partials/fonts';
@import '../../../../styles/partials/grid';

.memberTbl_head{
    @include toolbar;
    padding: 0px;
    button{
        @include nj_btn ;
        & + button{
            margin-left: 10px; 
        }
    }
   
}


.memberTbl{
    .memberGrid{
        thead{
            background-color: $primarycolor;
            th{
                color: $white-color;
            } 
        }
        td{
            .accepted{
                color: $accepted;
            }
            .itemsent{
                color: $secondrycolor;
            }
            .bp3-icon-remove,.bp3-icon-edit{
                color: $secondrycolor;
            }
            .resendInvite{
                display: inline-block;
                margin-left: 8px;
            }
            a{
                color: $secondrycolor;
                text-decoration: underline;
            }
            .disabledTag span{
                color: $grey_3;
                border-radius: 1px;
                text-align: center;
                cursor: not-allowed !important;
            }
            #enabled,.disabledTag span{
                width: 150px;
            }
        }
        .removeUser{
            margin-right: 8px;
        }
    }
}
