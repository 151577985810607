@import "../../../../styles/partials/palettes";
@import "../../../../styles/partials/typography";
@import "../../../../styles/partials/grid";
@import "../../../../styles/partials/mixin";
@import "../../../../styles/partials/fonts";

/*----------Dialoge Css-----*/
.bp3-dialog-container .enviteUserModal {
  background-color: $white-color !important;
  width: 100% !important;
  max-width: 900px;
  border-radius: 0;
  position: fixed;
  right: 0;
  top: 0;
  margin: 0;
  height: 100%;
  .bp3-dialog-header {
    padding: 20px;
    .bp3-heading {
      color: $black !important;
      font-weight: 500 !important;
      font-size: 20px;
    }
  }
  .warningMsg {
    background-color: #fbefda;
    border-radius: 5px;
    padding: 16px;
    font-size: $body-size;
    color: #6b4a0f;
    line-height: normal;
  }
  button.btn {
    @include nj_btn;
    text-align: center;
  }
  .blueHeaderTbl th{
    background-color: $primarycolor;
    color: $white-color;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .svaeUser-btn{
    @include nj_btn;
  }
}

.inviteUserForm {
  padding: 20px;
  @include grid;
  .nj-row {
    margin-bottom: 16px;
  }
  .MuiInputBase-input {
    height: auto;
  }
  .checkboxLbl {
    margin-left: 0px;
    .bp3-checkbox {
      margin: 0px;
    }
  }
  &_addButton{
    .inviteUser{
      @include stroke-btn;
    }
  }

}
.MuiPopover-root.MuiMenu-root.MuiModal-root {
  z-index: 9999;
}

