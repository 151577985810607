@import '../../../../styles/partials/grid';
@import '../../../../styles/partials/palettes';
@import '../../../../styles/partials/typography';
@import '../../../../styles/partials/mixin';

.planing-goal {
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;

    &__bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $border-color;
        padding-bottom: 10px;
        margin-bottom: 15px;

        h3 {
            font-size: $medium-size;
            font-weight: 600;
        }

        .gola-list {
            display: flex;
            align-items: center;
            .light-orange{
                margin-right: -5px;
                &:hover{
                    height: 32px;
                    width: 32px;
                    border-radius: 50px;
                    outline: 5px solid $hover-bg;
                    transition: all 0.3s ease-out;
                }
            } .MuiAvatar-circular{
                width: 32px;
                height: 32px;
                font-size: 13px;
                font-family: $primaryfont;
                border: 2px solid $status-notStarted;
            }

            .user-bar {
                span {
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    display: inline-block;
                    font-size: 10px;
                    text-align: center;
                    border-radius: 50%;

                    &.light-blue {
                        background-color: #E4E9FF;
                        color: #485CB7;
                    }

                    &.light-orange {
                        background-color: #F2DDF0;
                        color: #9A5994;
                    }
                }
            }

            ul {
                display: flex;
                margin-left: 50px;

                li {
                    margin-left: 10px;

                    button {
                        svg {
                            fill: $secondrycolor !important;
                            width: 18px;
                        }
                    }
                }
            }
        }

    }

    h2 {
        @include secondryheading;
        margin-bottom: 10px;
    }

    .hover-show{
        button{
            display: none;
            margin-left: 10px;
        }
        &:hover{
            button{
                display: inline-flex;
            }
        }
    }

    p {
        font-size: 16px;
        margin-bottom: 10px;

        &.light-txt {
            color: $grey-color;
            font-size: 14px;
        }
        &.hover-show{
            max-width: 1000px;
        }
    }

    &__button-group {
        margin-top: 20px;
        span{
            margin-top: -1px;
        }

        button {
            margin-right: 10px;
            @include stroke-btn;
            padding: 6px 20px;
            display: flex;
            align-items: center;            
        }
    }

    &__domain {
        margin: 22px 0;  
        .notfound-message.fullwidth-message{
            margin-right: 0;
        }      
    }

    h3 {
        font-weight: 700;
        font-size: $medium-size;
    }
    .search-label {
        max-width: 800px;
        margin-bottom: 10px;
        textarea {
            width: 100%;
        }
        .max-length{
            display: block;
            text-align: right;
        }
    }

    &__strategies{
        margin: 30px 0;
    }
    
}

.max-length-right{
    display: flex;
    justify-content: right;
}

.most-recent-survey{  
    padding: 0px 5px 10px;
    font-size: medium;
    justify-content: left;   
}

.dropdownlist {
    .MuiList-root {
        .MuiButtonBase-root {
            display: block;
            padding: 4px 10px;
            font-family: $primaryfont;
        }
    }
}

.popover-list {
    width: 500px;
    max-width: 100%;
    padding-bottom: 10px;
    max-height: 300px;
    // overflow: auto;

   
    .MuiPaper-root {
        margin: 0 !important;
    }

    .MuiAccordion-rounded {
        box-shadow: none;
    }

    .MuiAccordion-root {
        &:before {
            display: none;
        }
    }

    h4 {
        border-bottom: 1px solid $border-color;
        padding: 8px 15px;
        margin-bottom: 5px;
        font-weight: 600;
        font-size: $body-size;
    }

    .MuiAccordionSummary-root {
        min-height: inherit !important;
        padding: 5px 15px;
        padding-right: 5px;
        .MuiIconButton-root{
            padding-top: 0;
            padding-bottom: 0;
        }
        .MuiTypography-body1 {
            font-family: $primaryfont;
            font-size: $body-size;
            font-weight: 500;
        }

        .MuiAccordionSummary-content {
            margin: 2px 0 !important;
            margin-bottom: 0 !important;
            label.bp3-label{
                margin: 0;
                cursor: pointer;
                font-weight: 500;
            }
        }

        .MuiAccordionSummary-expandIconWrapper {
            transform: rotate(-90deg);

            &.Mui-expanded {
                transform: rotate(0deg);
            }
        }

        &:before {
            display: none;
        }
        &:hover{
            background: $hover-bg;
        }
    }

    .MuiCollapse-root {
        padding-left: 34px;
        padding-right: 20px;

        .MuiFormControlLabel-root {
            padding: 3px 0;
        }

        .MuiTypography-body1 {
            font-family: $primaryfont;
            font-size: $body-size;
        }

        .MuiAccordionDetails-root {
            padding: 0;
        }
    }

    .MuiCheckbox-root {
        margin-right: 5px;
    }
}

.popover-list-Selected-year {
    width: auto;
    max-width: 100%;
    padding-bottom: 10px;
    max-height: 300px;
    // overflow: auto;

    .MuiPaper-root {
        margin: 0 !important;
    }

    .MuiAccordion-rounded {
        box-shadow: none;
    }

    .MuiAccordion-root {
        &:before {
            display: none;
        }
    }

    h4 {
        border-bottom: 1px solid $border-color;
        padding: 8px 15px;
        margin-bottom: 5px;
        font-weight: 600;
        font-size: $body-size;
    }

    .MuiAccordionSummary-root {
        min-height: inherit !important;
        padding: 5px 15px;
        padding-right: 5px;
        .MuiIconButton-root{
            padding-top: 0;
            padding-bottom: 0;
        }
        .MuiTypography-body1 {
            font-family: $primaryfont;
            font-size: $body-size;
            font-weight: 500;
        }

        .MuiAccordionSummary-content {
            margin: 2px 0 !important;
            margin-bottom: 0 !important;
            label.bp3-label{
                margin: 0;
                cursor: pointer;
                font-weight: 500;
            }
        }

        .MuiAccordionSummary-expandIconWrapper {
            transform: rotate(-90deg);

            &.Mui-expanded {
                transform: rotate(0deg);
            }
        }

        &:before {
            display: none;
        }
        &:hover{
            background: $hover-bg;
        }
    }

    .MuiCollapse-root {
        padding-left: 34px;
        padding-right: 20px;

        .MuiFormControlLabel-root {
            padding: 3px 0;
        }

        .MuiTypography-body1 {
            font-family: $primaryfont;
            font-size: $body-size;
        }

        .MuiAccordionDetails-root {
            padding: 0;
        }
    }

    .MuiCheckbox-root {
        margin-right: 5px;
    }
}

.goal_list ul{
    margin-left: 10px !important;
}
@media(max-width:$l-devices){
    .planing-goal h2{
        font-size: $medium-l-size;
    }
}