@import '../../../../../../../styles/partials/palettes';
@import '../../../../../../../styles/partials/typography';
@import '../../../../../../../styles/partials/mixin';
@import '../../../../../../../styles/partials/fonts';

.large-modal{
    .MuiPaper-root{
        width: 1000px;
        max-width: 100%;
        .modal-title {
            padding: 10px 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $border-color;
            margin-bottom: 15px;
            h3{
                font-size: $medium-size;
                font-weight: 600;
            }
        }
        .summary-modal-content {
            padding: 0 15px;
            padding-bottom: 30px;
            h4 {
                text-align: center;
                font-weight: 600;
            }
            p{
                text-align: center;
            }
            .summary-modal-table-container{
                margin-top: 20px;
                box-shadow: 0 0 3px $border-color;
            .main-table, .sub-table{                
                table {
                    thead {
                        tr {
                            th {
                                text-align: left;
                                padding: 10px;
                                background-color: $primarycolor;
                                color: $white-color;
                            }
                        }
                    }
                    tbody{
                        tr{
                            td{
                                padding: 10px;
                                border-bottom: 1px solid $border-color;
                                .badge{
                                    position: relative;
                                    display: inline-block;
                                    width: 18px;
                                    height: 20px;
                                    text-align: center;
                                    margin-right: 10px;
                                    color: $white-color;
                                    font-size: $small-size;                                    
                                }                                                                
                            }
                            &:last-child{
                                td{
                                    border: none;
                                }
                            }
                            &:hover{
                                background-color: $hover-bg;
                            }
                        }
                    }
                }
            }
            .sub-table{
                table{
                    thead{
                        tr{
                            th{
                                background-color: #F2F2F2;
                                color: #333;
                            }
                        }
                    }
                }
            }
            }
        }
    }
}

