@import '../../../../../styles/partials/palettes';
@import '../../../../../styles/partials/typography';
@import '../../../../../styles/partials/mixin';
@import '../../../../../styles/partials/fonts';
@import '../../../../../styles/partials/grid';

.update-implementation-modal{
    .MuiDialog-scrollPaper{
        pointer-events: none;
        .MuiPaper-root{
            pointer-events: all;
            width: 1000px;
            max-width: 100%;
            position: fixed;
            right: 0;
            top: 0;
            height: 100%;
            padding: 0;
            margin: 0;
            border-radius: 0;
            box-shadow: 0 1px 3px $border-color;
            max-height: inherit;
            .modal-title {
                padding: 10px 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid $border-color;
                margin-bottom: 15px;
                h3{
                    font-size: $medium-l-size;
                    font-weight: 500;
                }
            }
            .update-implementation-modal-content {
                padding: 0 15px;
                padding-bottom: 30px;   
                h4{
                    font-size: $medium-size;
                    font-weight: 500;
                    margin-bottom: 20px;
                }
                .accordionbar{  
                    &__headaing {
                        display: flex;
                        justify-content: space-between;
                        h3{
                            font-size: $medium-size;
                            font-weight: 600;
                            line-height: 26px;
                        }
                        ul {
                            display: flex;
                            li {
                                margin-left: 10px;
                                a{
                                    color: $secondrycolor;
                                    font-size: $small-size;
                                    text-decoration: underline;
                                }
                                button{
                                    min-width: inherit;
                                    border: none !important;
                                    svg{
                                        fill:$secondrycolor !important;
                                    }
                                }
                                &:last-child{
                                    span{
                                        display: inline-block;
                                        width: 22px;
                                        height: 22px;
                                        line-height: 18px;
                                        text-align: center;
                                        background-color: $white-color;
                                        border: 1px solid $secondrycolor;
                                        color: $secondrycolor;
                                        font-size: $small-size;
                                        border-radius: 50%;
                                    }
                                }
                            }
                        }
                    }  
                    &__content{
                        padding-bottom: 15px;
                        margin: 0;
                        border-top: 1px solid $border-color;
                        @include grid;
                        &-row{            
                            padding: 10px;
                            background-color: $white-color;
                            border: 1px solid $border-color;
                            border-top: none;
                            a{
                                color: $secondrycolor;
                            }        
                        
                            &:hover{
                                background-color: #ECF4FC;
                                border:1px solid $border-color;
                                border-top: none;
                                .hvr-bar{
                                    text-decoration: underline;
                                }
                            }
                            .edit-core-lock {
                                position: relative;
                                padding-left: 0;
                                padding-right: 30px;
                                .bp3-icon {
                                    position: absolute;
                                    left: 0;
                                    top: 6px;
                                    svg{
                                        fill: #BBBBBB !important;
                                    }
                                }
                                .inputbar{
                                    margin-top: 10px;
                                    .MuiFormControl-root{
                                        width: 100%;
                                        *{
                                            font-family: $secondryfont;
                                            font-size: $body-size;
                                        }
                                        .MuiInputBase-root{
                                            background: $white-color;
                                        }
                                    }                                    
                                }
                                a{
                                    text-decoration: underline;
                                    margin-left: 5px;
                                    &:hover{
                                        text-decoration: none;
                                    }
                                }
                            }
                            .not-recurting{
                                .MuiFormControl-root {
                                    margin: 0;
                                    padding: 0;
                                    width: 100%;
                                    *{
                                        font-family: $primaryfont;
                                        font-size: $body-size;
                                    }
                                    svg{
                                        font-size: $medium-l-size;
                                    }
                                    .MuiSelect-select{
                                        background: $white-color;
                                    }
                                    .MuiFormLabel-root {
                                        padding: 0 5px;
                                    }
                                }
                                .dropdowlist{
                                    ul{
                                        li{
                                            background: none;
                                        }
                                    }
                                }
                            }
                            .delete-custom {
                                position: absolute;
                                right: 15px;
                                top: 19px;
                                svg{
                                    fill: $secondrycolor !important;
                                }
                            }
                        }
                        .custom-core-lock{
                            position: relative;
                            .edit-core-lock {
                                padding-left: 0;
                                display: flex;
                                align-items: center;
                                .inputbar{
                                    margin: 0;
                                    flex: 1 1;
                                }
                            }
                        }
                    }
                }   
                .action{
                    margin-top: 20px;
                    text-align: right;
                    .stroke-btn{
                        @include stroke-btn;
                    }
                    .nj-btn{
                        @include nj_btn;
                    }
                    button{
                        margin-left: 10px;
                    }
                }     
                .picker-info{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;
                    .MuiStack-root{
                        overflow: visible;
                        padding: 0;
                        *{
                            font-family: $primaryfont;
                            font-size: $body-size;
                        }
                        button{
                            svg{
                                font-size: $medium-l-size;
                            }
                        }
                    }
                    &__right{
                        button{
                            color: $secondrycolor;
                            display: flex;
                            align-items: center;
                            font-size: $medium-size;
                            span{
                                width: 15px;
                                height: 15px;
                                line-height: 13px;
                                align-items: center;
                                border-radius: 50%;
                                border: 1px solid $secondrycolor;
                                margin-right: 5px;
                                text-align: center;
                                display: inline-block;
                                font-size: 10px;
                            }
                        }
                    }
                }     
            }
        }
    }
}

.MuiPaper-root {
    .MuiList-root {
        li{
            &.status-dropdown {
                font-size: $body-size;
                font-family: $primaryfont;
                text-align: left;
                display: block;
            }
        }
    }
}

.status-progress{
    .MuiSelect-select{
        background-color: $status-progress !important;
    }
}

.status-notStarted{
    .MuiSelect-select{
        background-color: $status-notStarted !important;
    }
}

.status-done{
    .MuiSelect-select{
        background-color: $status-completed !important;
    }
}

.disabled-link {
  color: gray !important;
  pointer-events: none;
  text-decoration: none;
  cursor: default;
}