@import '../../../../../styles/partials/grid'; //'../../../../styles/partials/grid';
@import '../../../../../styles/partials/palettes';
@import '../../../../../styles/partials/typography';
@import '../../../../../styles/partials/mixin';
@import '../../../../../styles/partials/modal';

.goalstrategies-accordion{    
    .MuiAccordion-root{
        box-shadow: none; 
        background-color: $light-grey;
        border: 1px solid $border-color; 
        margin: 0 !important;
        margin-top: 10px !important;
        border-radius: 4px;
        .MuiAccordionSummary-root{
            position: static;
            padding: 0;
            min-height: inherit;            
            margin-bottom: 0;
            padding-right: 15px;
            padding-top: 5px;
            padding-bottom: 5px;
            border-radius: 4px; 
            display: flex;
            flex-direction: row-reverse;
            align-items: center;         
            .MuiAccordionSummary-content{
                display: block;
                margin: 0;
                p{
                    margin: 0;
                }
            }
            &.Mui-expanded{
                .MuiIconButton-root{
                    transform: rotate(0deg);
                }
            }
            .MuiButtonBase-root{
                color: #000;
            }
        }
        .MuiIconButton-root{
            padding: 0;
            transform: rotate(-90deg);
        }
        .MuiAccordionDetails-root{
            padding: 0;
            display: block;
        }
        &.Mui-expanded{
            border-color: #619CD1;
            box-shadow: 0 0 4px #619CD1;
        }
        &:hover {
        .accordionbar__headaing{
            ul{
                li{
                    button{
                        display: block;
                    }
                }
            }
        }
    }
    }  

    .accordionbar__headaing {
        display: flex;
        justify-content: space-between;
        h3{
            font-size: $body-size;
            font-weight: 400;
            line-height: 26px;
        }
        ul {
            display: flex;
            li {
                margin-left: 10px;
                a{
                    color: $secondrycolor;
                    font-size: $small-size;
                    text-decoration: underline;
                }
                button{
                    min-width: inherit;
                    border: none !important;
                    display: none;
                    svg{
                        fill:$secondrycolor !important;
                    }
                }
            }
        }
    }  
    &__content{
        padding-bottom: 15px;
        margin: 0 14px;
        border-top: 1px solid $border-color;
        @include grid;
        &-row{            
            padding: 5px 10px;
            background-color: $white-color;
            border: 1px solid $border-color;
            border-top: none;
            .nj-row{
                align-items: center;
            }
            a{
                color: $secondrycolor;
            }            
            .hvr-bar{
                display: inline-block;
                button{
                    font-family: $primaryfont;
                    font-weight: normal;
                    text-transform: none;
                }
                .bp3-icon-edit{
                    visibility: hidden;
                    opacity: 0;
                }
                &:hover{
                    .bp3-icon-edit{
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
            .action-bar {
                text-align: right;
                visibility: hidden;
                opacity: 0;
                svg{
                    fill:$black !important; 
                }
            }
            &:hover{
                .action-bar {
                    display: block;
                    opacity: 1;
                    visibility: visible;
                } 
            }
            .grade{
                span{
                    display: inline-block;
                    padding: 0 15px;
                    border-radius: 20px;
                    border: 1px solid $border-color;
                    margin-right: 5px;
                    background-color: $white-color;
                }
            }
            &:hover{
                background-color: #ECF4FC;
                border:1px solid $border-color;
                border-top: none;
                .hvr-bar{
                    text-decoration: underline;
                    button{
                        text-decoration: underline;
                        background: none;
                    }
                    .bp3-icon{
                        padding-left: 5px;
                    }
                    a{
                        text-decoration: inherit;
                    }
                }
            }
        }
    }
    .strategies-content__list{
        &-content{
            background: $white-color;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            padding: 14px;
            padding-bottom: 9px;
            &--domain{
                padding-top: 5px;
            }
        }
    }
}

.strategies-content__list::-webkit-scrollbar {
    width: 6px;              
  }
  
   .strategies-content__list::-webkit-scrollbar-thumb {
    background-color: grey;   
  }
  .strategies-content__list::-webkit-scrollbar-track {
    background-color: lightgray;   
  }
  
.dropdownlist {
    .MuiList-root {
        .MuiButtonBase-root {
            display: block;
            padding: 4px 10px;
            font-family: $primaryfont;
            min-width: 150px;
        }
    }
}
.medium-popup{
    @include smallpopup;
    text-align: center;
    padding: 20px;
    .cancel {
        text-align: right;        
    }
    .bp3-icon{
        justify-content: center;
        display: flex;
    }
    h3{
        font-size: $medium-size;
        font-weight: 600;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .button-group{
        margin-top: 20px;
        .nj-btn{
            @include nj_btn;
        }
        .stroke-btn{
            @include stroke-btn;
        }
        button{
            margin: 0 5px;
        }
    }
}

.action-bar .bp3-icon {
    cursor: pointer !important;
}

.load-more{
    text-align: center;
    flex: 1;
    .stroke-btn{
        @include stroke-btn;
    }
}