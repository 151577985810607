@import '../../../../../../../styles/partials/grid';
@import '../../../../../../../styles/partials/palettes';
@import '../../../../../../../styles/partials/typography';
@import '../../../../../../../styles/partials/mixin';
@import '../../../../../../../styles/partials/fonts';

.sidebar-modal  {
    > .MuiDialog-container{
        > .MuiPaper-root {
            position: fixed;
            top: 0;
            right: 0;
            margin: 0;
            border-radius: 0;
            width: 1000px;
            min-height: 100vh;
            display: block;
            max-width: 100%;
        }
    }
}
.sidepanel{
    &__header {
        padding: 3px 20px;
        border-bottom: 1px solid $border-color;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3{
            font-family: $medium-l-size;
            font-weight: 700;
        }
        svg{
            width: 18px;
            height: 18px;
        }
    }
    &__content{
        padding: 20px;
        h4{
            font-size: $medium-size;
            font-weight: 500; 
        }
        .select-list{
            margin: 20px 0;
            .MuiFormControl-root {
                margin: 0;
                width: 800px;
                max-width: 100%;
                .MuiFormLabel-root {
                    font-family: $primaryfont;
                }
                
                .MuiInputBase-root{
                    
                }
            }
        }
        .survey-item{
            .accordionbar{   
                 
                &__headaing {
                    display: flex;
                    justify-content: space-between;
                    h3{
                        font-size: $medium-size;
                        font-weight: 600;
                        line-height: 26px;
                    }
                    ul {
                        display: flex;
                        li {
                            margin-left: 10px;
                            a{
                                color: $secondrycolor;
                                font-size: $small-size;
                                text-decoration: underline;
                            }
                            button{
                                min-width: inherit;
                                border: none !important;
                                svg{
                                    fill:$secondrycolor !important;
                                }
                            }
                            &:last-child{
                                span{
                                    display: inline-block;
                                    width: 22px;
                                    height: 22px;
                                    line-height: 18px;
                                    text-align: center;
                                    background-color: $white-color;
                                    border: 1px solid $secondrycolor;
                                    color: $secondrycolor;
                                    font-size: $small-size;
                                    border-radius: 50%;
                                }
                            }
                        }
                    }
                }  
                &__content{
                    padding-bottom: 15px;
                    margin: 0;
                    border-top: 1px solid $border-color;
                    @include grid;
                    &-row{            
                        padding: 5px 10px;
                        background-color: $white-color;
                        border: 1px solid $border-color;
                        border-top: none;
                        a{
                            color: $secondrycolor;
                        }            
                        .hvr-bar{
                            button{
                                font-family: $primaryfont;
                                font-weight: normal;
                                text-transform: none;
                            }
                            .bp3-icon-edit{
                                visibility: hidden;
                                opacity: 0;
                            }
                            &:hover{
                                .bp3-icon-edit{
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }
                        }
                        .action-bar {
                            text-align: right;
                            display: none;
                            svg{
                                fill:$secondrycolor !important; 
                            }
                        }
                        &:hover{
                            .action-bar {
                                display: block;
                            } 
                        }
                        .grade{
                            position: relative;
                            padding-left: 58px;
                            span{
                                display: inline-block;
                                padding: 0 10px;
                                border-radius: 20px;
                                border: 1px solid #195A94;
                                margin-right: 5px;
                                background-color: $white-color;
                                font-size: $small-size;
                                height: 20px;
                                line-height: 18px;
                                position: absolute;
                                left: 0;
                                top: 1px;
                            }
                        }
                        &:hover{
                            background-color: #ECF4FC;
                            border:1px solid $border-color;
                            border-top: none;
                            .hvr-bar{
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
        .button-group{
            text-align: right;
            margin-top: 15px;
            button{
                margin-left: 10px;
                &.large-btn{
                    @include large-btn;
                }
            }
            .stroke-btn{
                @include stroke-btn;
            }
            .nj_btn{
               @include nj_btn;
            }
        }
    }

}
.MuiPaper-root {
    .MuiList-root {
        li {
            display: flex;
            padding: 5px 10px;
            .MuiTypography-body1{
                font-family: $primaryfont;
                font-size: $body-size;
            }
        }
    }
}

.bp3-portal{
    // z-index: 9999 !important;
}

ul li .accordionbar__content-row, ul li .accordionbar__content {
    border: none !important;
    background-color: transparent !important;
}

.css-tlelie-MuiListItemText-root{
    margin: 0;
    max-width: fit-content;
}

.itemTextEllipse span{
    width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    word-wrap: break-word;
}