@import '../../../../styles/partials/palettes';
@import '../../../../styles/partials/typography';
@import '../../../../styles/partials/mixin';
@import '../../../../styles/partials/fonts';
@import '../../../../styles/partials/grid';

.progressM{
    &-wrapper{
        .breadcrumbs{
            padding: 0px;
            ul li{
                padding-right: 5px;
                a{
                    color: $secondrycolor;  
                }
            }
        }
        .sci-Toolbar{
            font-family: $secondryfont;
            font-size: 28px;
            line-height: 36px;
            font-weight: 700;
            color: $primarycolor;  
            margin-top: 0;
            margin-bottom: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px;
            .stroke-btn{
                @include stroke-btn;
            }
           .infoIcon{
            font-size: $body-size;
            font-weight: 500;
            color: $secondrycolor;
            font-family:$primaryfont;
               .info-icon{
                width: 20px;
                height: 20px;
                display: inline-block;
                line-height: 18px;
                border: 1px solid #155b98;
                border-radius: 50%;
                margin-right: 2px;
               }
           }
        }
        .planning__left-list ul li.active {
            background:$hover-bg !important;
        }
        
        .planning__left-list ul li {
            display: block;
            width: 100%;
            position: relative;
            padding-left: 45px;
            padding-right: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            cursor: pointer;
            font-size:$medium-size;
        }
        
        .sort-option{
            padding:5px 10px;
        }
        
        .sort-option:hover{
            background:$hover-bg !important;
            cursor: pointer;
        }
        
        .goal-list-filter{
            ol{
                li{
                    min-width: 150px;
                }
            }
        }
        .planning__midd {
            border-left: 1px solid $border-color;
            margin-left: 240px;
            min-height: calc(100vh - 181px);
        }
        .planning__left{
            width: 240px;
            background: $white-color;
            max-height: inherit;
            &-searchbar{
                h2{
                    font-size: 18px;
                    font-family: $secondryfont;
                    font-weight: bold;
                }
            }
            &-links{
                li{
                    padding: 6px 6px 6px 0px;
                    position: relative;
                    &.progressMlinks a{
                     font-weight: 500;
                     display: -webkit-box;
                     -webkit-line-clamp: 2;
                     -webkit-box-orient: vertical;  
                     overflow: hidden;
                    }
                    &.active {
                     background-color:$hover-bg;

                     a {
                        color: $secondrycolor;
                     }
                    }
                    &.readableContent{
                        opacity: 0.8;
                        strong{
                            font-weight: normal;
                        }
                        button.editBtn {
                            position: absolute;
                            right: -5px;
                            top: 12px;
                            opacity: 0;
                            color: $secondrycolor;
                        }
                        &:hover{
                            button.editBtn{
                                opacity: 1;
                            }
                        }
                    }
                    a{
                        width: 100%;
                        display: block;
                        padding:  6px 20px;
                        color: $black;
                        font-size: $body-size;
                    }
                    .additionalInfo{
                        padding:  0 20px;
                    }
                    &:hover,&:focus{
                        background-color: $leftBar_bg;
                        a{
                            color: $secondrycolor;
                        }
                    }
                }
            }
            .meetings{
                padding:  8px 20px;
                border-bottom: 1px solid $border-color;
                color: $black;
            }

            .sciFilterFeilds {
                background-color:$white-color;
                padding: 16px;
                border-radius: 5px;
                box-shadow: 0px 0px 5px $border-color;
                margin-top: 8px;
                position: absolute;
                left: 20px;
                top: 40px;
                width: 94%;
                z-index: 1;
                .sciDatePicker{
                    margin-top: 8px;
                    .MuiStack-root{
                        overflow: visible;
                        .MuiTextField-root{
                            min-width: inherit;
                        }
                    }
                    input{
                        padding-top: 9px;
                        padding-bottom: 9px;
                        padding-right: 0px;
                        font-size: $body-size;
                    }
                 } 
                 .sciFilterBtn {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    margin-top: 32px;
                    margin-bottom: 8px;
                    .clearFilter{
                        color: $secondrycolor;
                    }
                    .nj-btn{
                        @include nj_btn;
                        margin-left: 12px;
                    }
                }

            }
        }


    }
}

/*----Left Bar css----*/
.sci-leftbar{
    height: 100vh;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $border-color;
    background-color: $leftBar_bg;
    width: 70px;
    float: left;
    z-index: 2;
    li{
        position: relative;
        button{
            width: 40px;
            height: 40px;
            display: block;
            background-color: $leftBar_bg;
            border-radius: 50px;  
            border: 2px solid $secondrycolor; 
            margin: 16px auto;
            min-width: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                width: 20px;
                height: 25px;
                path{
                    fill: $secondrycolor;
                }
            }   
        }
        &.activeItem{
            button{
                background-color: $secondrycolor;
                outline: 2px solid $white-color;
                svg{
                    path{
                        fill: $white-color;
                    }
                }
            }
        } 
    }
}


.planning__left, .planning__midd {
    transition: 500ms;
}
.collapse-toggle{
   & > .planning__left {
        margin-left: -240px;
    }
    .planning__midd{
        margin-left: 0px;
    }
}
.left-bar-toggle{
    z-index: 1;
}


/*-----SCI Middle Content SCSS------*/
.monitoringMid{
    &-topBar{
     display: flex;
     justify-content: space-between;
     align-items: flex-end;
     padding:18px 20px 16px 20px;
     button{
         @include stroke-btn;
         &.linkBtn{
            border: none;
            box-shadow: none;
            text-transform: capitalize;
            padding-left: 0px;
            padding-right: 0px;
            &:hover,&:focus{
                box-shadow: none !important;
                transform: none;
            }
            & + .linkBtn{
                margin-left: 8px;
            }
        }
     }
     h2{
         font-size: $large-size;
         font-family: $secondryfont;
         font-weight: bold;
     }
     .graphSelector{
         margin-top: 18px;
         max-width: 250px;
         width: 100%;
     }
    }
    &-content{
        .accordionRow{
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
            margin-bottom: 16px;
            border-radius: 5px;
            overflow: hidden;
            .MuiPaper-elevation1{
                box-shadow: none;
            }
            .MuiTypography-root{
                font-family: $primaryfont;
                font-size: $body-size;
            }
             .MuiAccordionSummary-root{
              background-color: $light-grey;
              border-bottom:1px solid $border-color;
              min-height: inherit;
              .MuiTypography-root{
                  font-weight: 600;
              }
              .MuiAccordionSummary-content{
                  &.Mui-expanded{
                      margin: 12px 0;
                  }
              }
          }
            .stroke-btn{
                @include stroke-btn;
                text-transform: capitalize;
                & + .stroke-btn{
                    margin-left: 14px;
                }
            }
            .accodionActions{
                padding:16px;
            }
            .MuiAccordionDetails-root{
                padding: 16px 16px 0;
            }
        }

    }
}
.MuiCheckbox-colorPrimary{
    margin-right: 5px !important;
}

.monitoring{
    &-content{
        padding:20px 20px 30px 20px;
    }
    &_header{
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: 0 16%;
        h3{
            font-weight: 700;
            font-size: 16px;
        }
        &_links{
            position: absolute;
            right: 0px;
            top: 0px;
            ul{
                display: flex;
                align-items: flex-end;
                li{
                    button{
                        @include stroke-btn;
                        &.linksBtn{
                           border: none;
                           box-shadow: none;
                           text-transform: capitalize;
                           padding:0px;
                           margin-left: 20px;
                           display: flex;
                           align-items: center; 
                           &:hover,&:focus{
                               box-shadow: none !important;
                               transform: none;
                           }
                       }
                    }
                }
            }
        }
    }
}
.m-auto{
margin:auto;
}
