
@import '../../../../../../styles/partials/palettes';
@import '../../../../../../styles/partials/fonts';

.strategies-goal_above-line{
    box-shadow: none;
    background-color: #f4f5f7;
    border: 1px solid #ccc;
    margin: 10px 0 0!important;
    border-radius: 4px;
}
