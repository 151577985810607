@import '../../../styles/partials/fonts';
@import '../../../styles/partials/palettes';

.header-bar {
    text-align: right;
    padding: 10px 20px;
    border-bottom: 1px solid $border-color;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 9;
    ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: end;
        align-items: center;
        li{           
            margin-left: 15px;
            a{
             color: $secondrycolor;
             display: flex;
             align-items: center;
             transition-property: transform;
            transition-timing-function: ease-out;
            transition-duration: 0.3s;
            &:hover{
                -webkit-transform: translateY(-3px);
                -ms-transform: translateY(-3px);
                    transform: translateY(-3px);
            }
             svg{
                 margin-right: 5px;
                 fill: $secondrycolor;
                 path{
                    fill: $secondrycolor; 
                 }
             }
            }
                button{
                    background:$profile-circle-bg;
                    width: 38px;
                    height: 38px;
                    text-align: center;
                    line-height: 39px;
                    font-weight: bold;
                    border-radius: 50%;
                    word-spacing: -2px;
                    img{
                        width: 38px;
                        height: 38px;
                        object-fit: cover;
                    }
                }
            
        }
    }

}
.profileList{
    .dropdown-list{
        position: relative;
        li{
            position: relative;
        }
    }
    .dropdown-list::before{
        content: "";
        display: block;
        position: absolute;
        top: 0px;
        right: 14px;
        width: 10px;
        height: 10px;
        background-color: rgb(255, 255, 255);
        transform: translateY(-50%) rotate(45deg);
        z-index: 0;
    }
    .MuiPaper-rounded{
        overflow: visible;
    } 
    .profileName{
        font-weight: bold;
        padding: 5px 10px;
        border-bottom: 1px solid rgba(16, 22, 26, 0.15);
    }
}
.left-align {
    text-align: left;
  }
  
  .right-align {
    text-align: right;
  }