@import '../../../../../../styles/partials/palettes';

.no-stragies-found{
    &.notfound-message{            
        text-align: center;           
        margin-top: 10px;
        max-width: 350px;
        margin: 0 auto;
        padding-top: 5%;
        .notfound-message_inner{
            background-color: #F8F8F8;
            border: 1px solid $border-color;
            border-radius: 5px;
            padding: 30px;
        }
        h3{
            border-bottom: 1px solid $border-color;
            padding-bottom: 5px;
            margin-bottom: 10px;
            font-weight: 500;
        }
        &.fullwidth-message{
            padding:  0;
            padding-top: 0;
            max-width: 100%;
            margin-right: 20px;
            h3{
                border: none;
                margin: 0;
            }
        }
        p{
            span{
                color: $secondrycolor;
            }
        }
    }
}