@import '../../../../styles/partials/palettes';
@import '../../../../styles/partials/typography';
@import '../../../../styles/partials/mixin';
@import '../../../../styles/partials/fonts';

.large-modal{
    .MuiPaper-root{
        width: 1000px;
        max-width: 100%;
        .bp3-dialog-body{
            padding: 0;
            margin: 0;
        }
        .modal-title {
            padding: 10px 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $border-color;
            margin-bottom: 15px;
            h3{
                font-size: $medium-l-size;
                font-weight: 500;
            }
        }
        .summary-modal-content {
            padding: 0 15px;
            padding-bottom: 30px;
            h4 {
                text-align: center;
                font-weight: 600;
                font-size: $medium-size;
                margin-bottom: 5px;
            }
            p{
                text-align: center;
            }
            .summary-modal-table-container{
                margin-top: 0;
                box-shadow: 0 0 3px $border-color;
                .main-table{
                    margin-top: 20px;
                }
            .main-table, .sub-table{                
                table {
                    width: 100%;
                    thead {
                        tr {
                            th {
                                text-align: left;
                                padding: 10px;
                                background-color: $primarycolor;
                                color: $white-color;
                            }
                        }
                    }
                    tbody{
                        tr{
                            td{
                                padding: 10px;
                                border-bottom: 1px solid $border-color;                                 
                                .flex {
                                    justify-content: flex-start;
                                    padding-left: 12px;
                                    .bookmark-count-sec{
                                        position: relative;
                                        .bookmark-count{
                                            position: absolute;
                                            left: 8px;
                                            font-size: 14px;
                                            color: #fff;
                                        }
                                    }
                                }                                                               
                            }
                            &:last-child{
                                td{
                                    border: none;
                                }
                            }
                            &:hover{
                                background-color: $hover-bg;
                            }
                        }
                    }
                }
            }
            .sub-table{
                table{
                    thead{
                        tr{
                            th{
                                background-color: #F2F2F2;
                                color: #333;
                            }
                        }
                    }
                }
            }
            }
        }
    }
}

