@import '../../../../../../styles/partials/palettes';
@import '../../../../../../styles/partials/typography';
@import '../../../../../../styles/partials/mixin';
@import '../../../../../../styles/partials/fonts';

.view-detail-cnt{
    padding: 20px;
    padding-bottom: 0;
    padding-top: 0;
    p{
        margin-bottom: 15px;
        font-size: $medium-size;
        line-height: 26px;
    }
    ul{
        li{
            font-size: $medium-size;
            line-break: 26px;
            margin-bottom: 10px;
            position: relative;
            padding-left: 18px;
            &:after{
                content: "";
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: #333;
                left: 0;
                top: 9px;
            }
        }
    }
}

