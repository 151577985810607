@import '../../../../../styles/partials/palettes';
@import '../../../../../styles/partials/typography';
@import '../../../../../styles/partials/mixin';
@import '../../../../../styles/partials/fonts';
@import '../../../../../styles/partials/grid';

.edit-core-modal{
    .MuiDialog-scrollPaper{
        pointer-events: none;
        .MuiPaper-root{
            pointer-events: all;
            width: 1000px;
            max-width: 100%;
            position: fixed;
            right: 0;
            top: 0;
            height: 100%;
            padding: 0;
            margin: 0;
            border-radius: 0;
            box-shadow: 0 1px 3px $border-color;
            max-height: inherit;
            .modal-title {
                padding: 10px 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid $border-color;
                margin-bottom: 15px;
                h3{
                    font-size: $medium-l-size;
                    font-weight: 500;
                }
            }
            .edit-core-modal-content {
                padding: 0 15px;
                padding-bottom: 30px; 
                .picker-info{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 15px;                   
                        button{
                            color: $secondrycolor;
                            display: flex;
                            align-items: center;
                            font-size: $medium-size;
                            span{
                                width: 15px;
                                height: 15px;
                                line-height: 13px;
                                align-items: center;
                                border-radius: 50%;
                                border: 1px solid $secondrycolor;
                                margin-right: 5px;
                                text-align: center;
                                display: inline-block;
                                font-size: 10px;
                            }
                        }
                }  
                h4{
                    font-size: $medium-size;
                    font-weight: 500;
                }
                .accordionbar{  
                    &__headaing {
                        display: flex;
                        justify-content: space-between;
                        h3{
                            font-size: $medium-size;
                            font-weight: 600;
                            line-height: 26px;
                        }
                        ul {
                            display: flex;
                            li {
                                margin-left: 10px;
                                a{
                                    color: $secondrycolor;
                                    font-size: $small-size;
                                    text-decoration: underline;
                                }
                                button{
                                    min-width: inherit;
                                    border: none !important;
                                    svg{
                                        fill:$secondrycolor !important;
                                    }
                                }
                                &:last-child{
                                    span{
                                        display: inline-block;
                                        width: 22px;
                                        height: 22px;
                                        line-height: 18px;
                                        text-align: center;
                                        background-color: $white-color;
                                        border: 1px solid $secondrycolor;
                                        color: $secondrycolor;
                                        font-size: $small-size;
                                        border-radius: 50%;
                                    }
                                }
                            }
                        }
                    }  
                    &__content{
                        padding-bottom: 15px;
                        margin: 0;
                        border-top: 1px solid $border-color;
                        @include grid;
                        &-row{            
                            padding: 15px 10px;
                            background-color: $white-color;
                            border: 1px solid $border-color;
                            border-top: none;
                            a{
                                color: $secondrycolor;
                            }        
                        
                            &:hover{
                                background-color: #ECF4FC;
                                border:1px solid $border-color;
                                border-top: none;
                                .hvr-bar{
                                    text-decoration: underline;
                                }
                            }
                            .inputbar{
                                margin-top: 10px;
                                padding-right: 30px;
                                .MuiFormControl-root{
                                    width: 100%;
                                    *{
                                        font-family: $primaryfont;
                                        font-size: $body-size;
                                    }
                                    .MuiInputBase-root{
                                        background: $white-color;
                                    }
                                }                                    
                            }
                            .edit-core-lock {
                                position: relative;
                                padding-left: 24px;
                                padding-right: 80px;
                                .bp3-icon {
                                    position: absolute;
                                    left: 0;
                                    top: 3px;
                                    svg{
                                        fill: #918c8c !important;
                                    }
                                }
                                
                                a{
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    &:hover{
                                        text-decoration: none;
                                    }
                                }
                            }

                            .not-recurting{
                                .MuiFormControl-root {
                                    margin: 0;
                                    padding: 0;
                                    width: 100%;
                                    padding-right: 30px;
                                    *{
                                        font-family: $primaryfont;
                                        font-size: $body-size;
                                    }
                                    svg{
                                        font-size: $medium-l-size;
                                    }
                                    .MuiSelect-select{
                                        background: $white-color;
                                    }
                                }
                                .dropdowlist{
                                    ul{
                                        li{
                                            background: none;
                                        }
                                    }
                                }
                            }
                            .delete-custom {
                                position: relative;
                                right: 20px;
                                float: right;
                                width: 0px;
                                z-index: 9;
                                svg{
                                    fill: $secondrycolor !important;
                                }
                            }
                        }
                        &-inputbarBottomIcons{
                            display: flex;
                            justify-content: end;
                            align-items: center;
                            margin-top: 5px;
                            button{
                                margin-left: 20px;
                                span.bp3-icon{
                                    position: static !important;
                                  & > svg path{
                                        fill: $secondrycolor !important;
                                    }
                                }
                            }
                            &.addComponent-icons{
                                    position: absolute;
                                    right:-38px;
                                    bottom: 8px;
                            }

                        }   
                        .custom-core-lock{
                            background: $light-grey;
                            position: relative;
                            padding-left: 35px;
                            .inputbar {
                                padding-right: 118px !important;
                                &.titleBar{
                                    padding-right: 38px !important;
                                }
                            }
                            .singleInputBar{
                                padding-right: 20px !important;
                                .inputbar {
                                    padding-right: 0px !important;
                                }
                            } 
                            .edit-core-lock {
                                padding-left: 0;
                                display: flex;
                                align-items: flex-start;
                                .inputbar{
                                    margin: 0;
                                    padding-right: 0px;
                                    flex: 1 1;
                                    &-content{
                                        // position: relative;
                                   
                                        &__editbutton{
                                            position: static;
                                            margin-left:15px;
                                            opacity: 0;
                                            span{
                                                position: static;
                                            }
                                            svg path{
                                                fill: $secondrycolor !important;
                                            }
                                        }
                                        &:hover{
                                            button{
                                                opacity: 1;
                                            }
                                        }
                                    }
                                    a {
                                        text-decoration: underline;
                                        margin-left: 10px;
                                        float: right;
                                    }
                                }
                            }
                            .delete-custom{
                                opacity: 0;
                            }
                            &:hover .delete-custom{
                                opacity: 1;
                            }
                        }
                    }


                }   
                .action{
                    margin-top: 20px;
                    text-align: right;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .stroke-btn{
                        @include stroke-btn;
                    }
                    .nj-btn{
                        @include nj_btn;
                    }
                    button{
                        margin-left: 10px;
                    }
                    &__left{
                        button{
                            margin-left: 0;
                        }
                    }
                } 
      
            }
        }
    }
}
.custom-core-lock-row{
    background-color: transparent !important;
}
.alertIcon{
    margin: auto;
}