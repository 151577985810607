html {
  background: #f7fafc;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.upcoming-event-section .meeating-details {
  display: block;
  padding: 15px;
}

.upcoming-event-section .meeating-details h5 {
    font-size: 16px;
  }

.upcoming-event-section .meeating-details .meeting-time {
    font-size: 13px;
    line-height: 23px;
    font-weight: 500;
    margin: 6px 0px;
  }

.upcoming-event-section .meeating-details .meeting-link {
    font-size: 15px;
    text-decoration: underline;
  }