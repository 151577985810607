@import '../../../../../styles/partials/grid';
@import '../../../../../styles/partials/fonts';
@import '../../../../../styles/partials/mixin';
@import '../../../../../styles/partials/palettes';

div.body-container:has(div.resourses-page) {
    padding: 0 !important;
}

.resourses-page{
    @include grid;
    .breadcrumbs {
        padding: 5px 20px;

        ul {
            display: flex;

            li {
                padding-right: 5px;

                a {
                    color: $secondrycolor;
                }
            }
        }
    }
    .toolbar{
        @include toolbar;
    }
    &__tab {
        .bp3-tabs {
            .bp3-tab-list {
                border-bottom: 2px solid $border-color;
                padding: 0 15px;

                .bp3-tab {
                    font-size: 16px;
                    // color: $disable;
                    padding: 5px;
                    border-bottom: 3px solid transparent;
                    font-weight: 500;

                    &[aria-selected="true"] {
                        color: $secondrycolor;
                        border-bottom-color: $secondrycolor;
                    }

                    &:hover {
                        color: $secondrycolor;
                        border-bottom-color: $secondrycolor;
                    }
                }

                .bp3-tab-indicator-wrapper {
                    display: none;
                }
            }
        }

        .bp3-tab-panel {
            margin-top: 0;

        }
    }
    &__content{
        padding: 15px 20px 30px 20px;
        .tab-buttonGroup{
            button{
                margin-left: 0px;
                & + button{
                    margin-left: 8px;
                }
            }
        }

        .MuiAccordion-root{
            margin-bottom: 20px;
            border-radius: 5px;
            box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.1), 0px 3px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 8px 0px rgba(0, 0, 0, 0.1);
        }
        .MuiTypography-root{
            @include SFProDisplay;
        }
        .MuiAccordionSummary-root{
           min-height: 35px;
           flex-direction: initial;
        }
        .MuiAccordionSummary-content{
            margin: 5px 0px;
           p {
                color: $black;
                font-weight: 50 0;
            }
        }
        .MuiAccordionSummary-content{
            font-weight: 500;
            @include SFProDisplay;
            margin-left: 0px;
        }
        .MuiAccordionDetails-root{
            padding: 16px;
            border-top: none;
        }
        .tabButton{
            background-color: $white-color;
            padding:4px 15px;
            border-radius: 5px;
            font-size: $medium-size;
            font-weight: 500;
            border: 1px solid $secondrycolor;
            color: $secondrycolor;
            &.activeTab{
                background-color: $secondrycolor;
                color: $white-color;
            }
        }
        .resourses-card{
            overflow: hidden;
            &:hover{
                border-color: #619CD1;
                box-shadow: 0 0 5px #619CD1;
            }
            .new-resource-bar {
                font-weight: 600 !important;
                font-size: $medium-size !important;
                padding: 10px 15px;
                flex-direction: row-reverse;
                justify-content: left;
                background-color: $light-grey !important;
                border-bottom: 1px solid $border-color;
            }
            .bg-white {
                align-items: center;
                padding: 9px 15px;

                button{
                    text-transform: capitalize !important;
                    border:none;
                    font-weight: 400;
                    padding-right: 0px;
                    padding-left: 0px;
                    font-size: $body-size;
                    letter-spacing: 0;
                    color: $secondrycolor;
                    line-height: normal;
                    font-family: "SF Pro Display" !important;
                    &:hover{
                        background-color: transparent;
                        text-decoration: underline;
                    }
                }
                .seprator{
                    margin: 0 8px;
                    color: $border-color;

                }
            }
        }
    }

    &__head{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 15px;
        margin-top: 20px;
        label.MuiInputLabel-shrink{
            background-color: $white-color;
        }
        .MuiFormControl-root{
            margin: 0px;
        }
    }
    .resourse__accHeading{
        margin-bottom: 20px;
        h3{
            @include SFProDisplay;
            font-size: $medium-l-size;
            font-weight: bold;
        }
        P{
            font-weight: 500;
            @include SFProDisplay;
        }
    }
    .accInner_conetnt{
        margin-bottom: 20px;
        @include SFProDisplay;
        a{
            color: $secondrycolor;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .accInner_button{
        button {
            color: $secondrycolor;
            text-transform: capitalize;
            & + button{
                margin: 0px 0px 0px 16px;
            }
        }
    }
  .MuiFormControl-root {
    * {
        $primaryfont: 'SF Pro Display';
        font-size: $body-size;
    }
    svg {
        font-size: $large-size;
    }
    }
    &__video{
        display: flex;
        margin: 0 -16px;
        flex-wrap: wrap;
        &-Thumb{
            flex:0 0 auto;
            width: 33.33333333%;
            padding:0 16px;
            margin-bottom: 40px;
            h5{
                font-size: $medium-size;
                font-weight: 600;
                $primaryfont: 'SF Pro Display';
                margin-top: 16px;
            }
        }
        .video-Thumbsourse{
            border:1px solid $border-color;
        }
    }
    
} 
.videorow{
    display: flex;
    margin: 0 -16px;
    flex-wrap: wrap;
    .videoCol{
        flex:0 0 auto;
        width: 33.33333333%;
        padding:0 16px;
        margin-bottom: 40px;
        .vedio_pop{
            font-size: $medium-size;
            font-weight: 600;
            $primaryfont: 'SF Pro Display';
            button{
                margin-bottom: 16px;
                border: 3px solid $border-color;
                border-radius: 0px;
            }
            
        }
    }
}
.resources_search{
    width: 250px
}
.activity-type{
    font-size: small;
}
.activity-type-custom{
    width: 100%;
}
.activity-name-custom{
    width: 10%;
    text-align: center;
    background-color: #d0cdd3;
    color: #4d4b4b;
    padding: 4px 12px;
    border-radius: 4px;
    font-size: x-small;
}
.MuiPaper-root .MuiList-root li{
    font-size: $body-size;
}

.resourses-page__acc-content .mt-5:has(.vedio_pop){
    width: 33.333%;
    display: inline-block;
}

@media(min-width:2100px){
    .resourses-page__video-Thumb{
        width: 25%;
    }
  
}