@import '../../styles/partials/grid';
@import '../../styles/partials/palettes';
@import '../../styles/partials/typography';
@import '../../styles/partials/mixin';
@import '../../styles/partials/fonts';


@media (min-width: 1280px){
    .container{
        max-width: 1200px !important;
    }
}
.container{
    margin: auto;
}
.registration{
    &-wrapper{
        @include grid;
        &__header{
            border-bottom: 1px solid $border-color_2;
            padding-bottom: 16px;
        }
        &__content{
            .form-header{
                color: $black;
                margin-top: 32px;
                h1{
                    @include PalanquinFont;
                    font-size: $large-size; 
                    font-weight: bold;
                }
                &__subTxt{
                    font-size: $medium-size;
                    margin:24px 0 16px 0;
                }
                &.termsBlock{
                    margin-top: 80px;
                }
            }
        }
        &__form{
            .formControl{
                margin-bottom: 16px;
                .MuiInputLabel-formControl{
                    font-size: $body-size;
                }
                input{
                    box-sizing: content-box;
                    font-size: 14px !important;
                    height: auto;
                }
                input:disabled{
                    background-color: $disabled_feilds;
                }
                button{
                    margin:0px;
                }
                .bp3-input-action{
                    top: 50%;
                }

            }
        }
        .nj-btn{
            @include nj_btn;
            background: $secondrycolor !important;
            color:$white-color !important;
            padding: 9px 20px;
            margin-top: 0px;
            margin-bottom: 40px;
            font-size: 13px !important;
            &.change-password-btn:disabled{
                background-color: $secondrycolor !important;
                color:$white-color !important;
                border:none !important;
                opacity: 0.5 !important;
            }
        }
        .pr{
            padding-right: 10px
        }
        .cstmColPad{
            padding-left: 45px;
            padding-right: 45px;
        }
        .MuiFormControlLabel-label{
            @include Sfprofont;
        }
        .errorMsg strong{
            font-weight: normal !important;
            padding-left: 5px;
        }

    }
   &-wclmsg{
       .terms-and-conditions-body{
           max-height: 185px;
           padding: 0px 15px 0px 0px;
           margin-bottom:16px;
           color: $black;
           background-color: $bluecolor_1;
           padding: 10px 15px;
           border-radius: 0px;
           a{
               color: $secondrycolor;
               text-decoration: underline;
           }
       }
   }

}

/*---992---*/
@media(max-width:$l-devices){
    .registration-wrapper{
        .nj-btn{
            margin:0px 0px 30px 0px;
        }
        &__content {
            .form-header__subTxt{
                margin: 30px 0px;
            }
            .termsBlock{
                margin-top: 25px !important;
            }
            .errorMsg {
                float: left;
                width: 100%;
                padding: 0 5px;
            }
        }
        .cstmColPad {
            padding-left: 25px;
            padding-right: 25px;
        }
    }
}
@media(max-width:$s-devices){
    .registration-wrapper{
        .cstmColPad {
            padding-left: 15px;
            padding-right: 15px;
        }
        .errorMsg strong{
            padding-left: 0px;
        }
    }
}