@import '../../../../styles/partials/palettes';
@import '../../../../styles/partials/typography';
@import '../../../../styles/partials/mixin';
@import '../../../../styles/partials/fonts';

.edit-profile-modal{
    .MuiDialog-scrollPaper{
        .MuiPaper-root{
            pointer-events: all;
            width: 400px;
            max-width: 100%;
            position: fixed;
            right: 0;
            top: 0;
            height: 100%;
            padding: 0;
            margin: 0;
            border-radius: 0;
            box-shadow: 0 1px 3px $border-color;
            max-height: inherit;
            .modal-title {
                padding:15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid $border-color;
                margin-bottom: 15px;
                text-transform: capitalize;
                h3{
                    @include PalanquinFont;
                    font-size: 20px;
                    font-weight: 600;
                    text-transform: capitalize;
                }
            }
            .edit-profile-modal-wrapper {
               
                padding-bottom: 30px;   
                .MuiFormControl-root{
                    margin: 0;
                    margin-bottom:15px;
                    display:block;
                    *{
                        font-family: $primaryfont;
                        font-size: $body-size;
                    }
                    .MuiInputBase-root {
                        display: block;
                        .MuiSelect-select{
                            box-sizing: border-box;
                        }
                        svg{
                            font-size: 24px;
                        }
                        .css-tlelie-MuiListItemText-root{
                            margin: 0;
                        }
                    }

                }
                .action{
                    padding: 0 15px;
                    margin-top: 20px;
                    text-align: right;
                    .stroke-btn{
                        @include stroke-btn;
                    }
                    .nj-btn{
                        @include nj_btn;
                    }
                    button{
                        margin-left: 10px;
                    }
                } 

            }
        }
    }
    button.deleteProfile_Pic {
        text-align: center;
        padding: 0;
        font-size: $small-size;
        text-decoration: underline;
        color: $secondrycolor;
        width: 100%;
    }
}
.Edit-customProfilePicture{
        width: 128px;
        height: 128px;
        margin: auto;
        position: relative;
    .circle {
        border-radius: 100% !important;
        overflow: hidden;
        width: 128px;
        height: 128px;
        background-color: $hover-bg;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .upload-button {
        font-size: 1.2em;
    }
    input[type="file"] {
        width: 100%;
    }
    .file-upload {
        display: none;
    }
    .image-editIcon{
        width: 16px;
        height: 16px;
        cursor: pointer;
    }
    .p-image {
        position: absolute;
        bottom: 10px;
        right: 0;
        height: 30px;
        width:30px;
        background-color: $secondrycolor;
        border-radius: 100%;
        display: flex;
        padding: 5px 6px;
        cursor: pointer;
        visibility: hidden;
    } 
    .image-editIcon path{
        color: white;
    }
    &:hover .p-image{
        visibility: visible;
    }

}
.profile__name{
    display: flex;
    align-items: center;
    margin-top: 35px;
    border-bottom: 1px solid rgba(16, 22, 26, 0.15);
    padding: 15px;
    .editIcon path {
        fill: $black;
    }
    .userName {
        margin-left:15px;
        font-weight: bold;
    }
    .editIcon{
        margin-left: 15px;
        visibility: hidden;
    }
    .usenameEdit-area{
        margin-left:15px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        input{
            border: 1px solid rgba(16, 22, 26, 0.15);
            padding: 7px;
            border-radius: 5px;
            width: 100%;
        }
    }
    &:hover .editIcon{
        visibility: visible;
    }
    
}

.profile__email{
    display: flex;
    align-items: center;
    margin-top: 15px;
    padding:0 15px;
    .emailLabel {
        margin: 0 15px;
        font-weight: bold;
    }

}
.profile__emailEdit{
    padding-left: 50px;
    padding-right: 15px;
    a{
        color: $secondrycolor;
        text-decoration: underline;
        margin-right: 15px;
    }
    .emailEditt-area{
        margin-top: 10px;
        input{
            border: 1px solid rgba(16, 22, 26, 0.15);
            padding: 7px;
            border-radius: 5px;
            width: 100%;
        }
    }
    .editIcon{
        visibility: hidden;
    }
    &:hover .editIcon{
        visibility: visible;
    }
}