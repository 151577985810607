@import '../../../../../../styles/partials/grid';
@import '../../../../../../styles/partials/fonts';
@import '../../../../../../styles/partials/mixin';
@import '../../../../../../styles/partials/palettes';

.bg-gray-100{
    background-color: $white-color !important;
}
.add-strategic{  
    @include grid;
    .breadcrumbs {
        padding: 5px 20px;
        ul {
            display: flex;
            li {
                padding-right: 5px;
                a{
                    color: $secondrycolor;
                }
            }
        }
    }
    .toolbar {
        @include toolbar;       
    }  
    .filterbar{
        padding: 20px;
        padding-left: 60px;
        padding-bottom: 10px;
        background-color:$strategic-filter-bg;
        position: relative;
        margin-bottom: 20px;
        .filter-icon {
            position: absolute;
            left: 20px;
            top: 24px;
            width: 30px;
            height: 30px;
            border: 1px solid #333;
            border-radius: 50%;
            text-align: center;
            line-height: 30px;
            svg{
                display: inline;
            }
        }
        .MuiFormControl-root{
            padding: 0;
            margin: 0;
            *{
                font-family: $primaryfont;
            }
            .MuiOutlinedInput-root {
                background-color: $white-color;
            }
        }
        .show-recom {
            margin-left: -11px;
            margin-top: 0;
        }
        .disabled-show-recom{
            color: gray;
        }
    }
    &__content{
        padding: 0 20px;         
        .left-bar{
           
            padding-left: 30px;
            .tablist {
                max-height: calc(100vh - 302px);
                overflow-y: auto;
            }
        }
        .action-bar{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 30px;
            margin-bottom: 30px;
            margin-bottom: 24px;
            .nj_btn{
                @include nj_btn;
            }
            .stroke-btn{
                @include stroke-btn;
            }
            .button-group{
                button{
                    margin-left: 10px;
                }
            }
        }
    }

    .strategies-list {
        padding-right: 30px;
        h4 {
            border-bottom: 1px solid #ddd;
            padding-bottom: 5px;
            margin-bottom: 10px;
            font-size: $body-size;
        }       
        .star-icon{
            margin-bottom: 5px;
            display: block;
            color: #9D9D9D;
            svg {
                fill: #EEC14D !important;
            }
        }
        > ul{
            max-height: calc(100vh - 380px);
            // max-height: 521px;
            overflow: auto;
            padding-right: 10px;
            padding-left: 2px;
            > li{
                border-radius: 5px;
                box-shadow: 0 0 5px $border-color;
                padding: 10px;
                margin-bottom: 15px;
                // margin-left: 3px;
                border: 1px solid transparent;
                .bluetext{
                    a{
                        color: $secondrycolor;
                        &:hover{
                            text-decoration: underline;
                        }
                    }                   
                }
                &:hover{
                    background-color: $hover-bg;
                    border: 1px solid #458CCB;
                }
                &.selected-item-strategy {
                    background-color: $hover-bg;
                    border: 1px solid #458CCB;
                }
                &:first-child{
                    margin-top: 5px;
                }
            }
        }
        .loadmore{
            text-align: center;
            margin-top: 15px;
            .stroke-btn{
                @include stroke-btn;
            }
        }
        &__topbar{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            h3{
                font-size: $medium-size;
                flex: 1 1 80%;
                font-weight: 500;
                span{
                    display: inline-block;
                    font-size: 12px;
                    background-color: #C9CBD4;
                    border-radius: 5px;
                    padding: 5px 10px;
                    margin-left: 10px;
                }

                .custom-strategy-district{
                    font-size: 12px;
                    background-color: #20285B;
                    color: #ffffff;
                    border-radius: 3px;
                    padding: 5px 10px;
                    margin-left: 10px;
                }
            }
            ul{
                display: flex;
                li{
                    margin-left: 5px;
                    span{
                        padding: 0;
                    }
                }
            }
        }
        
    }
    .no-stragies-found{
        &.notfound-message{            
            text-align: center;           
            margin-top: 50px;
            max-width: 350px;
            margin: 0 auto;
            padding-top: 14%;
            .notfound-message_inner{
                background-color: #F8F8F8;
                border: 1px solid $border-color;
                border-radius: 5px;
                padding: 30px;
            }
            h3{
                border-bottom: 1px solid $border-color;
                padding-bottom: 5px;
                margin-bottom: 10px;
                font-weight: 500;
                font-size: 1.125rem;
            }
            &.fullwidth-message{
                padding:  0;
                padding-top: 0;
                max-width: 100%;
                margin-right: 20px;
                h3{
                    border: none;
                    margin: 0;
                }
            }
            .action-bar{
                display: block;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-top: 30px;
                margin-bottom: 30px;
                margin-bottom: 24px;
                .nj_btn{
                    @include nj_btn;
                }
                .stroke-btn{
                    @include stroke-btn;
                }
                .button-group{
                    button{
                        margin-left: 10px;
                    }
                }
            }
            p{
                span{
                    color: $secondrycolor;
                }
            }
        }
    }
     .show-recom {
         padding: 0 8px;
     }
}
.tablist{
    .bp3-tabs{
        .bp3-tab-list{
            .bp3-tab-indicator-wrapper{
                display: none;
            }
            .bp3-tab{
                font-size: $medium-size;
                padding:2px 5px;
                font-weight: 600;
                border-bottom: 2px solid transparent;
                &[aria-selected="true"]{
                    color: $secondrycolor;
                    border-color: $secondrycolor;
                }
            }
        }
    }
    &__content{
        h3{
            font-size: $medium-l-size;
            margin-bottom: 10px;
            font-weight: 500;
            line-height: normal;
        }
        p{
            margin-bottom: 15px;
            font-size: $medium-size;
            line-height: 26px;
            a{
                color: $secondrycolor;
                font-weight: 500;
            }
        }
        .linked-domain{
            h4{
                margin-bottom: 5px;
                display: block;
                font-weight: 500;
                font-size: $medium-size;
            }
            ul{
                display: flex;
                flex-wrap: wrap;
                li{
                    background: #eee;
                    margin-right: 5px;
                    margin-bottom: 5px;
                    border-radius: 5px;
                    padding: 5px 14px;
                    display: flex;
                    align-items: center;
                    border-left: 3px solid;
                }
            }
        }
    }
}

.MuiPaper-root{
    .MuiList-root{
.filterbar__list {
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 0;
    position: sticky;
    top: 0;
    background: $white-color;
    z-index: 9;
    max-height: 100px;
    overflow: auto;
     ul {
        display: flex;
        flex-wrap: wrap;
        li {
            background: #eee;
            margin: 0;
            margin-right: 5px;
            margin-bottom: 5px;
            border-radius: 5px;
            padding: 2px 8px;
            font-size: $small-size;
            display: flex;
            align-items: center;
            border-left: 3px solid;
            span.bp3-icon {
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }
}
}
}

.clear-btn {
    position: sticky;
    bottom: 0;
    z-index: 9;
    background: $white-color;
    border-top: 1px solid $border-color;
    text-align: right;
    padding: 5px 10px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    color: $secondrycolor;
    button{
        svg{
            fill: $secondrycolor;
        }
    }
}

.warning-no-goal{
    color: #9B6B0E;
    font-size: 16px !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list{
    .checkbox-list{
        padding: 5px 10px;
        .MuiCheckbox-root{
            padding: 0;
            margin-right: 5px;
        }
        .MuiTypography-body1{
            font-family: $primaryfont;
            font-size: $body-size;
        }
    }
}
/* width */
::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
      background: $light-grey;
    border-radius: 5px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #D9D9D9;
  }

  .cnt-bar {
    height: calc(100vh - 200px);
    position: relative;
    .action-bar{
        position: absolute;
        width: 100%;
        bottom: -12px;
        left: 0;
    }
}


  @media(max-width:$xl-devices){
    .add-strategic__content .left-bar{
       padding-left: 0px;
    }
}