@import '../../../styles/partials/palettes';
@import '../../../styles/partials/typography';
@import '../../../styles/partials/grid';
@import '../../../styles/partials/mixin';
@import '../../../styles/partials/fonts';



/*----------Dialoge Css-----*/
.userEditDialoge{
  background-color:$white-color !important;
  width: 100% !important;
  max-width: 580px;
  .bp3-dialog-header{
    padding: 20px;
    .bp3-heading{
      color: $black !important;
      font-weight: 500 !important;
      font-size: 20px;

    }
  }
  .warningMsg {
    background-color: #FBEFDA;
    border-radius: 5px;
    padding: 16px;
    font-size: $body-size;
    color:#6B4A0F;
    line-height: normal;
  }
 button.btn{
   @include nj_btn;
   text-align: center;
 }
 .bp3-control.bp3-switch input ~ .bp3-control-indicator{
//  background:$gery_shade !important;
background: rgba(167, 182, 194, 0.2);
}
.bp3-control-indicator{
  width: 19px;
}

.bp3-control.bp3-switch .bp3-control-indicator::before{
//  background:$secondrycolor !important;
 // width: 15px;
  //height:  15px;
  box-shadow: none !important;
}
}



.editUserForm{
  @include grid;
  .nj-row{
    margin-bottom: 16px;
  }
  .MuiInputBase-input{
    height: auto;
  }
.checkboxLbl{
  margin-left: 0px;
  .bp3-checkbox{
    margin: 0px;
    width: 20.16px;
    height: 20.16px;
    
  }
}
}

.addUserBtn{
  button.bp3-button.bp3-intent-primary{
    @include nj_btn;
  }
}

.adduserTbl{
  border-radius: 4px;
  table{
    thead th{
      background-color: $primarycolor;
      color: $white-color !important;
      padding: 14px 12px !important;
      font-weight:500 !important;
      }
      td{
        background-color: transparent !important;
        box-shadow: none !important;
        .bp3-button{
          background-color: transparent !important;
          color: $secondrycolor !important;
          &:disabled{
            opacity: 0.8;
          }
          
        }
      }
  }
   
  }

  .anotherUser_btn{
    text-align: right;
    margin-top: 8px;
    button{
      padding-right: 0px !important;
      padding-top: 0px !important;
      @include stroke-btn;
      &.linkBtn{
         border: none;
         box-shadow: none;
         text-transform: capitalize;
         &:hover,&:focus{
             box-shadow: none !important;
             transform: none;
         }
     }
  }
  } 
  span.bp3-icon-info-sign{
    svg{
      background-image: url(../../../../src/static/images/infoIcon.svg);
      display: block;
      background-size: cover;
      path{
        display: none;
      }
    }
  }
  th span.bp3-icon-info-sign{
    svg{
      background-image: url(../../../../src/static/images/info_white.svg);
    }
  }

.userEditform{
  @include grid;
  .nj-row{
    margin-bottom: 16px;
  }
  input{
    height: auto;
  }
  .bp3-control.bp3-switch .bp3-control-indicator::before{
    background-color: #1F5C97 !important;
  }
  .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator{
    background-color: #99B1C6 !important;
  }
}
.warningIcon{
  height: 30px;
}
.infoIcon{
  margin-bottom: 16px;
}

  .bp3-dialog.bp3-alert.confimrTxt {
    background-color: #fff !important;
    .bp3-alert-footer {
      margin-top: 32px;
      button{
        width: 50% !important;
        line-height: normal !important;
      }
      button.bp3-button:not(.bp3-intent-primary){
        @include stroke-btn;
        margin-left: 0px;
        span{
          display: block;
          text-align: center;
        }
      }
      button.bp3-button.bp3-intent-primary{
        @include nj_btn;
        span{
          display: block;
          text-align: center;
        }
      }

    }
    .bp3-icon{
      color: $black;
    }
  }
  .bp3-alert-body {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .bp3-icon-info-sign {
      margin: 0 auto 16px auto !important;
    }

  }

.resendInvites{
  background-color: #fff !important;
  .bp3-alert-footer {
    margin-top: 32px;
    button{
      width: 50% !important;
     
    }
    button.bp3-button:not(.bp3-intent-warning){
      @include stroke-btn;
      margin-left: 0px;
      span{
        display: block;
        text-align: center;
      }
    }
    button.bp3-button.bp3-intent-warning {
      @include nj_btn;
      span{
        display: block;
        text-align: center;
      }
    }

  }
  .bp3-icon{
    color: $black !important;
  }
}