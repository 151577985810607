@import '../../../../styles/partials/grid';
@import '../../../../styles/partials/palettes';
@import '../../../../styles/partials/typography';
@import '../../../../styles/partials/mixin';
@import '../../../../styles/partials/modal';

.corecomponent-modal{
    @include smallpopup;
    .MuiPaper-root{
        padding: 0;
        width: 800px;
        max-width: 100%;
    }
    text-align: center;
    padding: 20px;
    .corecomponent-modal-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $border-color; 
        padding: 10px;
        margin-bottom: 15px;    
    }
    .bp3-icon{
        justify-content: center;
        display: flex;
    }
    h3{
        font-size: $medium-l-size;
        font-weight: 500;
    } 

    &__content{
        padding: 15px;
        padding-top: 0;
        text-align: left;
        h4 {
            font-weight: 600;
            margin-bottom: 20px;
            margin-top: 10px;
            font-size: $medium-size
        }
        li {
            padding-left: 18px;
            position: relative;
            margin-bottom: 10px;
            font-size: $medium-size;
            line-height: 26px;
            &:after {
                content: "";
                position: absolute;
                left: 1px;
                top: 10px;
                width: 5px;
                height: 5px;
                background: #333;
                border-radius: 50%;
            }
        }
    }
    
}

.d-inline{
    display: inline;
}