@import '../../../../styles/partials/palettes';
@import '../../../../styles/partials/typography';
@import '../../../../styles/partials/mixin';
@import '../../../../styles/partials/fonts';

.large-modal.disclaimer-details-modal{
    .MuiPaper-root{
        width: 800px;
        max-width: 100%;
        .modal-title {
            padding: 10px 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $border-color;
            margin-bottom: 15px;
            h3{
                font-size: $medium-l-size;
                font-weight: 500;
            }
        }
        .summary-modal-content {
            padding: 0 15px;
            padding-bottom: 30px;
            p{
                text-align: left;
                font-size: $medium-size;
                line-height: 26px;
            }
                     
        } 
    }
}

.disclaimer-top-rigth {
    position: relative;
    padding: 0 16px;
    a {
        color:$secondrycolor;
        text-decoration: underline;
        position: absolute;
        right: 20px;
        bottom: -30px;
        z-index: 1;
    }
}