@import '../../../../../styles/partials/palettes';
@import '../../../../../styles/partials/modal';
@import '../../../../../styles/partials/fonts';
@import '../../../../../styles/partials/mixin';
@import '../../../../../styles/partials/typography';

.delete-strategyPlan-popup{
    @include smallpopup;
    text-align: center;
    padding: 20px;
    .cancel {
        text-align: right;        
    }
    .bp3-icon{
        justify-content: center;
        display: flex;
    }
    h3{
        font-size: $medium-size;
        font-weight: 600;
        margin-top: 15px;
    }
    .button-group{
        margin-top: 20px;
        .nj-btn{
            @include nj_btn;
        }
        .stroke-btn{
            @include stroke-btn;
        }
        button{
            margin: 0 5px;
        }
    }
}