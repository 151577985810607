@import '../../../../../styles/partials/palettes';
@import '../../../../../styles/partials/typography';
@import '../../../../../styles/partials/mixin';
@import '../../../../../styles/partials/fonts';
@import '../../../../../styles/partials/grid';

.blueHeader_tbl{
    table{
        width: 100%;
    }
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    thead{
        th{
            color: $white-color;
            background-color: $primarycolor;
            font-weight: 100;
            padding-top: 10px;
            padding-bottom: 10px;
            line-height: normal;
        }
    }
    .greenscore{
        color: $greenscore;
    }
    .redscore{
        color: $redscore;
    }
}
.p-datatable .p-column-header-content {
    display: flex;
    align-items: center;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #343a40;
    margin-left: 0.5rem;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #eaeaea;
    margin-left: 0.5rem;
}
.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border-width: 0 0 1px 0;
    font-weight: 700;
    transition: box-shadow 0.2s;
}
.p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
}

ul:has(div.clear-btn) {
    padding-bottom: 0 !important;
}
.indicatorCircles {
    position: relative;
}
span.circleTrack {
    height: 1px;
    width: 23px;
    display: inline-block;
    position: absolute;
    left: -35px;
    background-color: #ffa401;
    top: 12px;
}
.circlegrp div{
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 100px;
    background-color: #ffa401;
    margin-right: 2px;
}
span.circlegrp {
    position: absolute;
    top: -13.5px;
    left: 3px;
}

.graphScroll{
    width: 800px; 
    overflow-x: scroll; 
    scrollbar-width: 50px;
}

.graphScroll::-webkit-scrollbar{

        width: 50px !important; 
        height: 0.70em;
      /* Firefox */
      scrollbar-width: thick;
      scroll-behavior: smooth;
}
.graphScroll::-webkit-scrollbar-track-piece {

   background-color: whitesmoke;
  /* Firefox */
  scrollbar-width: thick;
}

.graphScroll::-webkit-scrollbar-button {
    background: white
}