@import '../../../styles/partials/grid';
@import '../../../styles/partials/fonts';
@import '../../../styles/partials/mixin';
@import '../../../styles/partials/palettes';
@import '../../../styles/partials/typography';

div.body-container:has(div.strategic-plan) {
    padding: 0 !important;
}

.bg-gray-100 {
    background-color: $white-color !important;
}

.strategic-plan {
    @include grid;

    .breadcrumbs {
        padding: 5px 20px;

        ul {
            display: flex;

            li {
                padding-right: 5px;

                a {
                    color: $secondrycolor;
                }
            }
        }
    }

    .toolbar {
        @include toolbar;
    }

    &__tab {
        .bp3-tabs {
            .bp3-tab-list {
                border-bottom: 1px solid $border-color;
                padding: 0 20px;

                .bp3-tab {
                    font-size: 16px;
                    // color: $disable;
                    padding: 5px;
                    border-bottom: 3px solid transparent;
                    font-weight: 500;
                    background-position: center;
                    transition: background 0.8s;

                    &[aria-selected="true"] {
                        color: $secondrycolor;
                        border-bottom-color: $secondrycolor;
                    }

                    &:hover {
                        color: $secondrycolor;
                        border-bottom-color: $secondrycolor;
                        background: #fff radial-gradient(circle, transparent 1%, #fff 1%) center/15000%;
                    }
                    &:active {
                        background-color: #b7cfff !important;
                        background-size: 100%;
                        transition: background 0s;
                    }
                }

                .bp3-tab-indicator-wrapper {
                    display: none;
                }
            }
        }

        .bp3-tab-panel {
            margin-top: 0;

        }
    }
}

.planning {
    &__left {
        position: relative;
        float: left;
        width: 240px;
        max-height: calc(100vh - 181px);
        display: flex;
        flex-direction: column;

        .left-bar-toggle {
            position: absolute;
            right: -21px;
            top: 0;
            background: $light-grey;
            width: 20px;
            height: 20px;
            line-height: 20px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px; 
            cursor: pointer;
            -webkit-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;
            &:hover{
                box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
            }
        }

        &-topbar {
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;


            ul {
                display: flex;

                li {
                    margin-left: 10px;
                    &:last-child{
                        button{
                            position: relative;
                            top: -2px;
                        }
                    }
                }
            }
        }

        &-searchbar {
            padding: 0 20px;
            margin-bottom: 15px;
        }

        &-list {
            flex: 1 1 100%;
            overflow: auto;
            ul {
                li {
                    display: block;
                    width: 100%;
                    position: relative;
                    padding-left: 45px;
                    padding-right: 20px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    cursor: pointer;

                    .list-icon {
                        position: absolute;
                        left: 20px;
                        top: 10px;
                        span.bp3-icon.bp3-icon-warning-sign {
                            background: #F9A72D;
                            border-radius: 50%;
                            width: 17px;
                            height: 17px;
                            text-align: center;
                            line-height: 14px;
                            margin-right: 9px;
                            svg{
                                fill: $white-color;
                                display: inline-block;
                                width: 11px;
                            }
                        }
                    }

                    .warning-sign {
                        svg {
                            fill: $warning;
                        }
                    }

                    .check-sign {
                        svg {
                            fill: $success;
                            width: 17px;
                            height : 17px;
                            
                        }
                    }

                    .list-content {
                        h3 {
                            color: $secondrycolor;
                            font-weight: 500;
                            font-size: $body-size;
                            text-align: left;
                            text-transform: capitalize;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }

                        p {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }

                    &:hover {
                        background-color: $hover-bg;
                    }
                }
            }

        }
    }

    &__right {
        margin-left: 240px;
        border-left: 1px solid $border-color;
        padding-left: 30px;
        min-height: calc(100vh - 181px);

        .notfound-message {
            text-align: center;
            max-width: inherit;
            margin: 0 auto;
            padding-top: 0;

            &_inner {
                background-color: #F8F8F8;
                border: 1px solid $border-color;
                border-radius: 5px;
                padding: 30px;

            }

            h3 {
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 0;
                font-weight: 600;
                margin-bottom: 5px;
            }
            &.no-goal-found{
                   height: calc(100vh - 190px);
                   display: flex;
                   flex-wrap: wrap;
                   align-items: center;
                   justify-content: center; 
            }
        }
    }
}

.planning__left, .planning__right{
    transition: 500ms;
}

.collapse-toggle {
    overflow: hidden;
    position: relative;
}

.collapse-toggle > .planning__left {
    margin-left: -240px;
}

.collapse-toggle > .planning__right {
    margin-left: 20px;
    padding-left: 0;
    border: none;
}


.toolbar {
    @include toolbar;
    .playButton{
        .playButtonBg{
            background-color: $playbuttonBg;
            display: inline-flex;
            width: 20px;
            height: 20px;
            border-radius: 100px;
            vertical-align: middle;
            padding: 0 3px;
            margin-right: 5px;
            margin-top: -3px;
            align-items: center;
            span{
                color: $white-color;
                vertical-align: middle;
            }
        }
    }
}

@media(max-width:$l-devices){
    .strategic-plan{
        &__tab{
            .bp3-tab{
                font-size: $body-size !important;
                margin-right: 8px !important; 
            }
        }
    }
}