@import '../../../../../styles/partials/typography';
@import '../../../../../styles/partials/palettes';

.goal-activity{
    .bp3-tabs{
        .bp3-tab-list{
            padding: 0;
        }
    }
    &__content{
        max-height: 300px;
        overflow: auto;
        ul{
            margin-top: 20px;
            li{
                margin-top: 15px;
                span{
                    width: 34px;
                    height: 34px;
                    line-height: 34px;
                    display: inline-block;
                    font-size: $medium-size;
                    text-align: center;
                    border-radius: 50%;
                    margin-right: 10px;
                    &.light-blue {
                        background-color: #E4E9FF;
                        color: #485CB7;
                    }
                    &.light-orange {
                        background-color: #F2DDF0;
                        color: #9A5994;
                    }
                }
            }
        }
    }
    .comment {
        max-width: 600px;
        &__sec {
            position: relative;
            padding-left: 44px;
            span {
                position: absolute;
                left: 0;
                top: 0;
            }
            button{
                margin-right: 10px;
                margin-top: 10px;
                color: $secondrycolor;
                font-weight: 700;
            }
        }
    }
}
