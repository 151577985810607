@import '../../../../../styles/partials/grid';
@import '../../../../../styles/partials/palettes';
@import '../../../../../styles/partials/typography';
@import '../../../../../styles/partials/mixin';
@import '../../../../../styles/partials/fonts';

.progressM_filterlist{
    .filterbar{
        padding-top: 0px;
        padding-bottom: 0px;
        display: flex;
        align-items: center;
        .filterRange {
            min-width: inherit!important;
            width: 100%;
            input{
                padding: 8.5px;
            }
            .rs-picker-toggle-value{
                color: $bodycolor;
            }
        }
        .MuiStack-root{
            padding-top: 0px;
        }
        .filterCol {
            flex: 0 0 18%;
            max-width:18%;
            margin-right:10px;
            .MuiCheckbox-root {
                margin-right: 5px;
            }
            &_wrapper{
                display: inline-block;
                overflow-x: auto;
                padding-top: 8px;
                padding-bottom: 5px;
                width: 100%;
                white-space: nowrap;
            }
            .rs-picker-toggle{
                padding-top: 8px;
                border-radius: 3px;
                border-color: $border-color;
            }
        }
            
    }
    &-listSave{
        display: inline-flex;
        button{
            border: 1px solid $secondrycolor;
            width: 23px;
            height: 23px;
            border-radius: 100px;
            margin: 0 8px;
            color: $secondrycolor;
        }
    }

}
.MuiListSubheader-sticky{
    font-weight: 500 !important;
    color: $black !important;
    border-bottom: 1px solid $border-color;
    line-height: normal !important;
    margin-top: 10px;
    padding-bottom: 10px !important;
    margin-bottom: 5px;
}
.filtergroup{
    width: 100%;
    display: flex;
    align-items: center;
    overflow-x: auto;
    padding: 10px 0;
    margin-left: 10px;
    .MuiFormControl-root{
        margin-left: 0px !important;
    }
}
.progressM__button-group{
    margin-top: 0px;
    .popover-list{
        margin-bottom: 0px;
        .MuiCollapse-wrapper{
            margin-top: 10px;
        }
    }
}
.progressM__item-group{
    margin-top: 0px;
    .popover-list{
        padding-bottom: 0px;
    }
    .itemLbl{
        margin-bottom: 0;
        padding: 8px 0;
    }
}
@media (max-width:$xl-devices){
    .progressM_filterlist .filterbar .filterCol {
        max-width: 30%;
        flex: 0 0 30%;
    }
    .collapse-toggle .progressM_filterlist .filterbar .filterCol {
        max-width: 25%;
        flex: 0 0 25%;
    }
}

.MuiListSubheader-sticky {
    font-weight: bold !important;
}

// .collapse-toggle .progressM_filterlist .filterbar .filterCol_wrapper .filterCol {
//     max-width: 175px;
// }