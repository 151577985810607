@import './palettes';
@import './typography';
@import './fonts';

@mixin nj_btn {
    padding:7px 20px;
    border-radius: 5px;
    background-color: $secondrycolor;
    border: 1px solid #3488D1;
    color: $white-color;
    font-size: 13px;
    font-weight: 500;
    font-family: 'SF Pro Display';
    text-transform: uppercase;
    line-height: 21px;
    display: inline-block;
    box-shadow: 0px 1px 3px $border-color;
    -webkit-transition: all ease-in-out 0.1s;
    -o-transition: all ease-in-out 0.1s;
    transition: all ease-in-out 0.1s;
    &:hover{
        background-color: #2f7dc1; 
        box-shadow: 0px 3px 3px $border-color;
    }
    &:disabled{
        opacity: 0.6;
    }
    &:active{
        -webkit-transform: scale(0.98);
        -ms-transform: scale(0.98);
        transform: scale(0.98);
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    }
}

@mixin stroke-btn {
    padding:7px 20px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 13px;
    color: $secondrycolor !important;
    background-color: $white-color !important;
    border: 1px solid $secondrycolor;
    box-shadow: 0px 1px 3px $border-color;
    text-transform: uppercase;
    line-height: 21px;
    display: inline-block;
    -webkit-transition: all ease-in-out 0.1s;
    -o-transition: all ease-in-out 0.1s;
    transition: all ease-in-out 0.1s;
    &:hover{
        box-shadow: 0px 3px 3px $border-color !important;
    }
    &:disabled{
        opacity: 0.6;
    }
    &:active{
        -webkit-transform: scale(0.98);
        -ms-transform: scale(0.98);
        transform: scale(0.98);
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    }
}

@mixin large-btn{
    padding:10px 20px;
    font-size: $medium-size;
}

@mixin toolbar{
        padding: 0 20px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1{
            font-family: 'Palanquin';
            font-size: 28px;
            line-height: 36px;
            font-weight: 700;
            color: $primarycolor;
        }
        ul{
            display: flex;
            align-items: center;
            li{
                padding-left: 15px;
                a{
                    display: flex;
                    color: $secondrycolor;
                    font-weight: 500;
                    svg{
                        margin-right: 5px;
                        width: 19px;
                    }
                }
                .nj-btn{
                   @include nj_btn; 
                   display: flex;
                   align-items: center;
                }
                button {
                    color: $secondrycolor;
                    font-weight: 500;
                    span.info-icon {
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                        line-height: 18px;
                        border: 1px solid $secondrycolor;
                        border-radius: 50%;
                        margin-right: 2px;
                    }
                }
            }
        }
}

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
  }

  @mixin w-h($width,$height){
      width: $width;
      height: $height;
  }
  @mixin content-end-center {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  @mixin content-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @mixin padding ($top: null, $right: null, $bottom: null, $left: null) {
      padding-top: $top;
      padding-right: $right;
      padding-bottom: $bottom;
      padding-left: $left;
  }
