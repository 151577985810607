@import '../../../../styles/partials/palettes';
@import '../../../../styles/partials/typography';
@import '../../../../styles/partials/mixin';
@import '../../../../styles/partials/fonts';
@import '../../../../styles/partials/grid';


.createNote_editor{
    // float: left;
    flex:1;
    // border-left: 1px solid $border-color;
    height: 100vh;
    .sciMid-topBar{
        padding: 5px 20px 5px 20px;
        border-bottom: 1px solid $border-color;
        position: relative;
        h2{
            margin-top: 0px;
        }
        a{
            color: $secondrycolor;
            .bp3-icon{
                vertical-align: middle;
                margin-top: -3px;
            }
        }
    }
    .editorWrapper{
        padding:0 18px 20px;
    }
    .filesWrapper{
        text-align: left;
        border:1px dotted $border-color;
        margin: 0 20px;
        .labelHeading{
            border-bottom: 1px solid $border-color;
            padding: 15px 20px;
        }
        &-buttonsDiv{
            text-align: center;
            padding: 15px 20px;
            button{
                @include stroke-btn;
                & + button{
                    margin-left: 8px;
                }
            }
        }
        &-uploadedFiles{
            padding: 5px 20px;
            .filesName_Chips{
                display: inline-block;
                background-color: $grey-color_shade1;
                border-radius: 5px;
                padding: 8px 40px 6px 12px;
                vertical-align: middle;
                font-size: 13px;
                position: relative;
                line-height: normal;
                margin-right: 8px;
                margin-bottom: 5px;
                &::before{
                    vertical-align: middle;
                    margin-right: 5px;
                    width: 18px;
                    height: 20px;
                    display: inline-block;
                }
                &.doc-icon::before{
                    content:url(../../../../static/icons/svg/wordIcon.svg);
                }
                &.docx-icon::before{
                    content:url(../../../../static/icons/svg/wordIcon.svg);
                }
                &.pdf-icon::before{
                    content:url(../../../../static/icons/svg/pdfIcon.svg);
                }
                &.jpg-icon::before{
                    content:url(../../../../static/icons/svg/jpgIcon.svg);
                }
                &.png-icon::before{
                    content:url(../../../../static/icons/svg/pngIcon.svg);
                }
                &.csv-icon::before{
                    content:url(../../../../static/icons/svg/csvIcon.svg);
                }
                .xlsx-icon::before{
                    content:url(../../../../static/icons/svg/exceLicon.svg);
                }
                &.ppt-icon::before{
                    content:url(../../../../static/icons/svg/powerpoint.svg);
                }
                &.pptx-icon::before{
                    content:url(../../../../static/icons/svg/pptx.svg);
                }
                &.hyperlink-icon::before{
                    content:url(../../../../static/icons/svg/hyperlink-icon.svg);
                }
            }
            .chipDelete_Btn{
                @include position(absolute, $top: 8px, $right: 10px, $bottom: null, $left: null);
                padding: 2px 2px;
                height: 18px;
                width: 18px;
                border-radius: 50px !important;
                &:hover{
                    background-color:$grey-color_shade1;
                }
            }
        }
    }
    &--actionButton{
        text-align: right;
        margin-top: auto;
        padding: 15px 20px;
        .stroke-btn{
            @include stroke-btn;
        }
        .solid-btn{
            @include nj_btn;
            margin-left: 8px;
        }
    }
    &--feilds{
        @include grid;
        padding-right: 18px;
        padding-left: 18px;
        margin-bottom: 20px;
        input{
            height: auto;
        } 
    }
    .left-bar-toggle {
        @include position(absolute, $top: 0, $right: null, $bottom: null, $left: -21px);
        background: $light-grey;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px; 
        cursor: pointer;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        &:hover{
            box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
        }
    }

}

.smallest-modal{
    .MuiPaper-root{
        width: 400px;
        max-width: 100%;
        .modal-title {
            padding: 10px 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $border-color;
            margin-bottom: 15px;
            h3{
                font-size: $medium-l-size;
                font-weight: 500;
            }
        }
        .modalBody{
            padding: 0 16px;
        }
    }
    .modalFooter{
        padding: 10px 15px;
        text-align: center;
        button{
            @include nj_btn;
            background: $secondrycolor !important;
            color:$white-color !important;
            padding: 9px 20px;
            margin-top: 0px;
            font-size: 13px !important;
            &:disabled{
                background-color: $secondrycolor !important;
                color:$white-color !important;
                border:none !important;
                opacity: 0.5 !important;
            }
        }
    }
}
.greyHead-table{
    th{
        background-color: $gery_shade;
        line-height: normal;
    }
    th:first-child,td:first-child{
        padding-left: 22px !important;
    }
    .editBtn,.deleteBtn{
        color: $secondrycolor;
    }
    .deleteBtn{
        margin-left: 8px;
    }
}

.iconDiv svg{
    display: inline-block;
}
.redAsterisk {
    color: red;
  }

.stroke-btn{
    border: 2px solid !important;
}

.jodit-wrapper {
    &.enableEditor {
        pointer-events: none;
    }
    .jodit-toolbar__box {
        background-color: #fff;

        &:not(:empty):not(:empty) {
            background-color: #fff;
        }
        svg {
            stroke: #444;

            path {
                fill: #444;
            };
        }
    }
    .jodit-ui-separator {
        display: none;
    }
    table {
        border-collapse: collapse;
        width: 100%;

        th,
        td {
            border: 1px solid #ddd;
            padding: 8px;
        }

        th {
            background-color: #f2f2f2;
            text-align: left;
        }
    }
}

.sciMid-content table {
    border-collapse: collapse;
    width: 100%;
}

.sciMid-content th, .sciMid-content td {
    border: 1px solid #ddd;
    padding: 8px;
}

.sciMid-content th {
    background-color: #f2f2f2;
    text-align: left;
}

.jodit-wrapper table {
    display: table;
    list-style: none;
  }