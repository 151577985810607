@import '../../../../../styles/partials/grid'; //'../../../../styles/partials/grid';
@import '../../../../../styles/partials/palettes';
@import '../../../../../styles/partials/typography';
@import '../../../../../styles/partials/mixin';
@import '../../../../../styles/partials/modal';

.accordionbar{    
    .MuiAccordion-root{
        box-shadow: none; 
        background-color: $light-grey;
        border: 1px solid $border-color;  
        border-left: 5px solid;   
        margin: 0 !important;
        margin-top: 10px !important;
        border-radius: 4px;
        .MuiAccordionSummary-root{
            display: flex;
            position: static;
            padding: 0;
            min-height: inherit;            
            margin-bottom: 0;
            padding-right: 15px;
            padding-top: 5px;
            padding-bottom: 5px;
            border-radius: 4px;   
            flex-direction: row-reverse;         
            .MuiAccordionSummary-content{
                display: block;
                margin: 0;
                p{
                    margin: 0;
                }
            }
            &.Mui-expanded{
                .MuiIconButton-root{
                    transform: rotate(0deg);
                }
            }
            .MuiButtonBase-root{
                color: #000;
            }
        }
        .MuiIconButton-root{
            padding: 0;
            transform: rotate(-90deg);
        }
        .MuiAccordionDetails-root{
            padding: 0;
            display: block;
        }
        &.Mui-expanded{
            border-color: #619CD1;
        }
        &:hover {
        .accordionbar__headaing{
            ul{
                li{
                    button{
                        display: block;
                    }
                }
            }
        }
    }
    }  

    &__headaing {
        display: flex;
        justify-content: space-between;
        padding-left: 15px;
        h3{
            font-size: $body-size;
            font-weight: 500;
            line-height: 26px;
        }
        ul {
            display: flex;
            li {
                margin-left: 10px;
                a{
                    color: $secondrycolor;
                    font-size: $small-size;
                    text-decoration: underline;
                }
                button{
                    min-width: inherit;
                    border: none !important;
                    display: none;
                    svg{
                        fill:$secondrycolor !important;
                    }
                }
            }
        }
    }  
    &__content{
        padding-bottom: 15px;
        margin: 0 14px;
        border-top: 1px solid $border-color;
        @include grid;
        &-row{            
            padding: 5px 10px;
            background-color: $white-color;
            border: 1px solid $border-color;
            border-top: none;
            .nj-row{
                // align-items: center;
                .grade {
                    position: relative;
                    padding-left: 55px;
                    word-break: break-word;
                    top: 3px;
                    span {
                        display: inline-block;
                        padding: 0 10px;
                                border-radius: 20px;
                                border: 1px solid #195A94;
                                margin-right: 5px;
                                background-color: $white-color;
                                font-size: $small-size;
                                height: 20px;
                                line-height: 18px;
                                position: absolute;
                                left: 0;
                                top: 1px;
                    }
                }
            }
            a{
                color: $secondrycolor;
            }            
            .hvr-bar{
                display: inline-block;
                button{
                    font-family: $primaryfont;
                    font-weight: normal;
                    text-transform: none;
                }
                .bp3-icon-edit{
                    visibility: hidden;
                    opacity: 0;
                }
                &:hover{
                    .bp3-icon-edit{
                        visibility: visible;
                        opacity: 1;
                    }
                }
                input{
                    border-radius: 5px !important;
                }
            }
            .action-bar {
                text-align: right;
                visibility: hidden;
                opacity: 0;
                margin-bottom: 0px;
                svg{
                    fill:$black !important; 
                }
            }
            &:hover{
                .action-bar {
                    display: block;
                    opacity: 1;
                    visibility: visible;
                } 
            }
            .grade{
                span{
                    display: inline-block;
                    padding: 0 15px;
                    border-radius: 20px;
                    border: 1px solid $border-color;
                    margin-right: 5px;
                    background-color: $white-color;
                }
            }
            &:hover{
                background-color: #ECF4FC;
                border:1px solid $border-color;
                border-top: none;
                .hvr-bar{
                    text-decoration: underline;
                    button{
                        text-decoration: underline;
                        background: none;
                    }
                    .bp3-icon{
                        padding-left: 5px;
                    }
                    a{
                        text-decoration: inherit;
                    }
                }
            }
        }
    }
}

.dropdownlist {
    .MuiList-root {
        .MuiButtonBase-root {
            display: block;
            padding: 4px 10px;
            font-family: $primaryfont;
            min-width: 150px;
        }
    }
}
.medium-popup{
    @include smallpopup;
    text-align: center;
    padding: 20px;
    .cancel {
        text-align: right;        
    }
    .bp3-icon{
        justify-content: center;
        display: flex;
    }
    h3{
        font-size: $medium-size;
        font-weight: 600;
        margin-top: 15px;
    }
    .button-group{
        margin-top: 20px;
        .nj-btn{
            @include nj_btn;
        }
        .stroke-btn{
            @include stroke-btn;
        }
        button{
            margin: 0 5px;
        }
    }
}

.action-bar .bp3-icon {
    cursor: pointer !important;
}

@media(max-width:$l-devices){
    .accordionbar__headaing h3{
        line-height: 1.5;
    }
    .hvr-bar{
        text-align: center;
    }
}