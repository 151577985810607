@import './fonts';

$large-size:24px;
$medium-l-size:20px;
$medium-size: 16px;
$small-size: 12px;
$body-size: 14px;
$s-devices:576px;
$m-devices:768px;
$l-devices:992px;
$xl-devices:1200px;
$xxl-devices:1400px;

@mixin secondryheading {
    font-family: 'Palanquin';
    font-size: $large-size;
    font-weight: 700;
    line-height:1.2;
}
@mixin PalanquinFont{
    font-family: 'Palanquin';
}
@mixin SFProDisplay {
    font-family: 'SF Pro Display';
}
@mixin Sfprofont {
    font-family: 'SF Pro Display';
}