@import "../../../../styles/partials/palettes";
@import "../../../../styles/partials/typography";
@import "../../../../styles/partials/mixin";
@import "../../../../styles/partials/fonts";

.sidearea {
  width: 600px;
  .domain-title {
    padding: 5px 15px;
    font-size: 20px;
    color: #000;
    font-weight: 500;
  }
  .domain-card-content {
    padding: 0px;
    border-bottom: none;
    .class-room {
      padding: 15px 50px;
      border-bottom: 1px solid #cbcbcb;
      table {
        width: 100%;
        th {
          text-align: left;
          font-size: 14px;
          color: #4c4c4c;
          font-weight: 700;
          padding: 0px 0px 10px;
        }
        td,
        a {
          font-size: 14px;
          padding-bottom: 10px;
        }
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #195a94;
          cursor: pointer;
          svg {
            margin-right: 10px;
          }
        }
      }
      h5 {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        margin-bottom: 20px;
      }
    }
    .question-list {
      padding: 15px 50px 0px;
      h6 {
        font-size: 14px;
        font-weight: 700;
        line-height: 28px;
        color: #4c4c4c;
        margin-bottom: 10px;
      }
      ul {
        padding: 15px;
        li {
          list-style-type: disc;
          font-size: 16px;
          line-height: 16px;
          font-weight: 400;
          color: #4c4c4c;
          margin-bottom: 10px;
        }
      }
    }
    .footer-botton {
      padding: 0px 50px 5px;
      text-align: right;  
      .btn {
        padding: 5px 15px;
        margin: 10px 0px 10px 10px;
        text-transform: uppercase;
        border-radius: 5px;
        font-size: 13px;
        font-weight: 600;
      } 
      .btn-secoundary {
        border: 1px solid #619CD1;
        color: #619CD1;
      }
      .btn-primary {
        border: 1px solid #619CD1;
          background-color: #619CD1;
          color: #fff;
      }
    }
  }
}