@import '../../styles/partials/grid';
@import '../../styles/partials/palettes';
@import '../../styles/partials/typography';
@import '../../styles/partials/mixin';
@import '../../styles/partials/fonts';



.congratulationModal {
      width: 355px;
      height: 268px;
      position: absolute;
      background: $white-color;
      z-index: 99;
      border-radius: 5px;
      margin: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      text-align: center;
      padding: 12px;
      box-shadow: 0px 0px 6px #ccc;
      img{
        margin: auto;
      }
      h1{
        color: #60B952;
        font-size: 24px;
        font-weight:bold;
        margin-bottom: 16px;
      }
      p{
        color: #4C4C4C;
        font-size: 18px;
      }
      button{
        background-color: $secondrycolor;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        color: $white-color;
        padding: 8px 24px;
        text-transform: uppercase;
        margin-top: 24px;
      }
}