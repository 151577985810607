
@import '../../../../../../styles/partials/palettes';
@import '../../../../../../styles/partials/fonts';

.progress-bar{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    span{
        width: 100px;
        border-radius: 10px;
        height: 10px;
        display: block;
        background: #D9D9D9;
        margin-left: 10px;
        span {
            margin-left: 0;
        }
    }
}