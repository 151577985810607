
@import '../../../../../../styles/partials/palettes';
@import '../../../../../../styles/partials/fonts';

.strategy-actions{
    &.dropdown-list{
        li{
            a{
                padding: 5px 10px;
                min-width: 150px;
                display: block;
                color: $secondrycolor;
                text-transform: capitalize;
                &:hover{
                    background: $hover-bg;
                }
                .bp3-icon {
                    margin-right: 5px;
                }
            }
        }
    }
}