@import '../../../../styles/partials/palettes';
@import '../../../../styles/partials/typography';
@import '../../../../styles/partials/mixin';
@import '../../../../styles/partials/fonts';

.change-password-modal{
    .MuiDialog-scrollPaper{
        .MuiPaper-root{
            pointer-events: all;
            width: 400px;
            max-width: 100%;
            position: fixed;
            right: 0;
            top: 0;
            height: 100%;
            padding: 0;
            margin: 0;
            border-radius: 0;
            box-shadow: 0 1px 3px $border-color;
            max-height: inherit;
            .modal-title {
                padding:15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid $border-color;
                margin-bottom: 15px;
                text-transform: capitalize;
                h3{
                    @include PalanquinFont;
                    font-size: 20px;
                    font-weight: 600;
                    text-transform: capitalize;
                }
            }
            .change-password-modal-content {
                padding: 0 15px;
                padding-bottom: 30px;   
                .MuiFormControl-root{
                    margin: 0;
                    margin-bottom:15px;
                    display:block;
                    *{
                        font-family: $primaryfont;
                        font-size: $body-size;
                    }
                    .MuiInputBase-root {
                        width: 100%;
                        .MuiSelect-select{
                            box-sizing: border-box;
                        }
                        svg{
                            font-size: 20px;
                        }
                        .css-tlelie-MuiListItemText-root{
                            margin: 0;
                        }
                    }

                }
                .action{
                    margin-top: 20px;
                    text-align: right;
                    .stroke-btn{
                        @include stroke-btn;
                    }
                    .nj-btn{
                        @include nj_btn;
                    }
                    button{
                        margin-left: 10px;
                    }
                }                 
            }
        }
    }
}

.change-password-feilds{
    .MuiFormControl-root{
        margin-top: 10px !important;
    }
}