@import '../../../../styles/partials/grid';
@import '../../../../styles/partials/fonts';
@import '../../../../styles/partials/mixin';
@import '../../../../styles/partials/palettes';
@import '../../../../styles/partials/typography';



.uploadMaterials{
    &_box {
        @include grid;
        border:1px dashed $border-color;
        max-width: 650px;
        width: 100%;
        margin: auto;
        text-align: center;
        padding:40px 100px;
        margin-top: 50px;
        button{
            @include nj_btn;
            margin: 0 5px;
        }
    }
    &_btns{
        margin: 25px 0px;
    }
}
.blueHeader_tbl{
    .entityActionS{
        .bp3-button{
            background-color: transparent !important;
            color:$secondrycolor !important;
            box-shadow: none;
            &:hover{
                box-shadow: none;
            }

        }
    }
}
.entityTbl{
    .font-bold{
        font-weight: normal;
    }
    .bp3-html-table{
      thead th{
        color: #fff !important;
        padding: 10px 12px !important;
        font-weight: 500;
      }
      td{
        background-color: #fff !important;
        box-shadow: none !important;
        padding: 10px 12px;
      } 
    }
  }