@import '../../../../styles/partials/fonts';
@import '../../../../styles/partials/palettes';
@import '../../../../styles/partials/typography';

.site-navigation{
    height: 100vh;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $border-color;
    background: #E5EEF5;
    width: 260px;
    z-index: 9;
    .logo {
        text-align: center;
        background: $white-color;
        border-bottom: 1px solid $border-color;
        height: 100px;
        overflow: visible !important;
        img {
            width: auto;
            display: inline;
            max-height: 84px;
            margin-top: 6px;
            margin-bottom: 5px;
        }
    }
   
    &_filter{
        padding: 0 15px;
        padding-top: 15px;
        label{
            font-weight: 700;
            color: $primarycolor;
            display: block;
            margin-bottom: 5px;
            text-transform: uppercase;
            font-size: $body-size;
        }
        .select-plan{
            margin-bottom: 10px;
            h3{
                margin: 0;
                padding: 0;
                border: none;
                font-size: $body-size;
                letter-spacing: 0;
            }
        }
        
    }
    h3{
        font-size: $medium-size;
        border-bottom: 1px solid #ABB8CA;
        padding-bottom: 10px;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 15px;
        padding-left: 15px;
        color: $primarycolor;
    }
    .menu-list{
        flex: 1 1 100%;
        overflow: auto;
        margin-top: 15px;
        .bp3-popover-wrapper{
            display: block;
            .bp3-popover-target{
                display: block;
                .bp3-button {
                    background: none;
                    border: none;
                    box-shadow: none;
                    padding: 7px 15px;
                    min-height: inherit;
                    border-radius: 0;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    color: $primarycolor;
                    font-size: $body-size;
                    font-weight: 500;
                    svg{
                        fill: $primarycolor;
                    }
                    &:hover{
                        background-color: #D5DFE8; 
                        transition: all 0.3s ease-in;                       
                    }
                    &.bp3-intent-primary{
                        background-color: $secondrycolor;
                        color: $white-color;
                        svg{
                            fill: $white-color;
                            path{
                                fill: $white-color; 
                            }
                        }
                    }
                    .bp3-button-text{
                        display: flex;
                        align-items: center;
                        svg{
                            margin-right: 10px;
                            width: 16px;
                        }
                    }
                }
            }
        }
        .menu-accordion{
            margin-top: 15px;
            *{
                font-family: $primaryfont;
            }
            .MuiPaper-root {
                background: none;
                box-shadow: none;
                border-radius: 0;
                border: none;
                margin: 0;                
                .MuiButtonBase-root {
                    padding: 10px 15px;
                    min-height: inherit;
                    border-bottom: 1px solid #ABB8CA;
                    .MuiAccordionSummary-content {
                        margin: 0;
                        padding: 0;
                        font-size: $body-size;
                        font-weight: 700;
                        color: $primarycolor;
                        text-transform: uppercase;
                    }
                    svg{
                        fill: $primarycolor;
                    }
                }
                .MuiCollapse-root {
                    .MuiAccordionDetails-root {
                        padding: 0;
                        display: block;
                    }
                }
                &:before{
                    display: none;
                }
            }
        }
    }
    .user-details{
        padding: 0 15px;
    }
}