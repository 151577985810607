@import '../../../../../../styles//partials/grid';
@import '../../../../../../styles/partials/palettes';
@import '../../../../../../styles/partials/typography';
@import '../../../../../../styles/partials/mixin';
@import '../../../../../../styles/partials/modal';

.create-strategy{
    @include smallpopup;
    .MuiPaper-root{
        padding: 0;
        width: 400px;
        max-width: 100%;
    }
    text-align: center;
    padding: 20px;
    .create-strategy-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $border-color; 
        padding: 10px;
        margin-bottom: 15px;    
    }
    .bp3-icon{
        justify-content: center;
        display: flex;
    }
    h3{
        font-size: $medium-size;
        font-weight: 600;
    }
    .search-label{
        margin: 10px;
    }
    .button-group{
        margin-top: 20px;
        margin-bottom: 30px;
        .nj-btn{
            @include nj_btn;
        }
        .stroke-btn{
            @include stroke-btn;
        }
        button{
            margin: 0 5px;
        }
    }
}