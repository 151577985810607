@import '../../../../styles/partials/grid';
@import '../../../../styles/partials/palettes';
@import '../../../../styles/partials/typography';
@import '../../../../styles/partials/mixin';
@import '../../../../styles/partials/fonts';


.viewActivity{
  max-width: 750px !important;
}
.addExistinguser,.viewActivity{
    background-color:$white-color !important;
    width: 100% !important;
    max-width: 580px;
    .bp3-dialog-header{
      padding:12px 20px;
      .bp3-heading{
        color: $black !important;
        font-weight: 500 !important;
        font-size: 20px;
  
      }
    }
    .warningMsg {
      background-color: #FBEFDA;
      border-radius: 5px;
      padding: 16px;
      font-size:$small-size;
      color:#6B4A0F;
      line-height: normal;
    }
    .bp3-dialog-footer{
      button.bp3-button{
        @include nj_btn;
        text-align: center;
      }
    }
  }
  .addExistinguser{
    .bp3-dialog-body{
      margin-left: 0px;
      margin-right: 0px;
      .MuiFormControl-root {
        width: 98%;
        margin-left: 0px;
        margin-right: 0px;
        ul li{
          padding: 8px 0 8px 20px;
          .customLabel{
            text-transform: capitalize;
            font-size: 13px;
          }
          &:hover{
            background-color: $menuHover;
          }
        }
      }
    }
  }
  
 
  

  
  
